import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "element-theme-darkplus";
import "element-theme-darkplus/lib/index.color.css";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css"; //使用
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value),
});
Vue.use(Element);

import { Icon } from "ant-design-vue";
Vue.use(Icon);

// 导入全局样式表
import "./assets/css/global.css";

// 导入iconfont字体库
import "../static/font/iconfont/iconfont.css";
// 导入多语言文件
import i18n from "./tools/i18n.js";
import lang from "./tools/lang.js";

window.lang = lang;

import VueClipBoard from "vue-clipboard2";

Vue.use(VueClipBoard);

// 引入echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

import "@/permission"; // permission control
import * as filters from "./filters"; // global filters

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
