<template>
  <div class="web-footer">
    <div class="footer_wrap">
      <div class="footer_wrap-left">
        <!--                <div class="dropdown-wrap">-->
        <!--                    <div class="dropdown-title" @click="listShow = !listShow">-->
        <!--                        <i class="iconfont icon-huore"></i> {{ $t('layout.footer.hot') }} ：-->
        <!--                    </div>-->
        <!--                    <div class="dropdown-body" v-show="listShow">-->
        <!--                        <div class="drop-div">-->
        <!--                            <div class="list-wrap">-->
        <!--                                <div class="list-header">-->
        <!--                                    <div class="list-cell">-->
        <!--                                        <div class="td-cell">-->
        <!--                                            {{ $t('layout.footer.sort') }} | {{ $t('layout.footer.code') }}-->
        <!--                                        </div>-->
        <!--                                        <div class="td-cell">-->
        <!--                                            {{ $t('layout.footer.buy_price') }}|{{ $t('layout.footer.sell_price') }}-->
        <!--                                        </div>-->
        <!--                                        <div class="td-cell">-->
        <!--                                            {{ $t('layout.footer.chg') }}-->
        <!--                                        </div>-->
        <!--                                    </div>-->

        <!--                                </div>-->
        <!--                                <div class="list-body">-->
        <!--                                    <div class="scroll-body">-->
        <!--                                        <div class="list-view">-->
        <!--                                            <div class="list-cell" :class="nowId === i ? 'active' : ''" v-for="i in 9">-->
        <!--                                                <div class="td-cell">-->
        <!--                                                    <div class="symbol-name">-->
        <!--                                                        <div class="market_closed">-->

        <!--                                                        </div>-->
        <!--                                                        <span>美元/加元</span>-->
        <!--                                                    </div>-->
        <!--                                                    <div class="symbol-label">-->
        <!--                                                        USDCAD-->
        <!--                                                    </div>-->
        <!--                                                </div>-->
        <!--                                                <div class="td-cell profit">-->
        <!--                                                    <div>-->
        <!--                                                        1.36046-->
        <!--                                                    </div>-->
        <!--                                                    <div>-->
        <!--                                                        1.3607-->
        <!--                                                    </div>-->
        <!--                                                </div>-->
        <!--                                                <div class="td-cell loss">-->
        <!--                                                    <div>-->
        <!--                                                        +0.18%-->
        <!--                                                    </div>-->
        <!--                                                    <div>-->
        <!--                                                        +0.00242-->
        <!--                                                    </div>-->
        <!--                                                </div>-->
        <!--                                            </div>-->

        <!--                                        </div>-->
        <!--                                    </div>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <ul>-->
        <!--                    <li class="profit">-->
        <!--                        <span class="noraml_color">黄金</span>-->
        <!--                        <span>5.65 / 5.68</span>-->
        <!--                        <span>-3.42%</span>-->
        <!--                    </li>-->
        <!--                    <li class="loss">-->
        <!--                        <span class="noraml_color">澳元/美元</span>-->
        <!--                        <span>1.25830 / 1.25839</span>-->
        <!--                        <span>-3.42%</span>-->
        <!--                    </li>-->
        <!--                </ul>-->
      </div>
<!--      <div class="footer_wrap-right">-->
<!--        <i class="iconfont icon-time"></i>-->
<!--        <span class="locale-color">{{ nowTime | parseTimeNew('YYYY-MM-DD HH:mm:ss') }}</span>-->
<!--        <i class="iconfont icon-lianjie profit"></i>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

export default {
  props: {},
  data() {
    return {
      timer: null,
      nowTime: new Date(),
      remindType: false,
      listShow: false,
      nowId: 3,
      tableData: [
        {
          id: 0,
          title: "澳元/纽元",
          bitTit: "AUDNZD",
          sellPrice: 1.045613,
          buyPrice: 1.265465,
          rate: 0.00124,
        },
        {
          id: 1,
          title: "澳元/纽元",
          bitTit: "AUDNZD",
          sellPrice: 1.045613,
          buyPrice: 1.265465,
          rate: -0.00124,
        }
      ]
    };
  },
  created() {
    this.getTime()
  },
  computed: {},

  methods: {
    getTime() {
      var _this = this;
      this.timer = setInterval(() => {
        _this.nowTime = new Date()
      }, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="less" scoped>
.web-footer {
  border-top: 1px solid var(--background-w1);
  font-size: 11px;
  padding: 8px 12px;

  .profit {
    color: var(--color-p1);
  }

  .loss {
    color: var(--color-l1);
  }

  .footer_wrap {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer_wrap-left {
      display: flex;
      align-items: center;

      .dropdown-wrap {
        position: relative;
        font-size: var(--el-font-size-base);
        -webkit-app-region: no-drag;

        .dropdown-title {
          cursor: pointer;
        }

        .dropdown-body {
          position: absolute;
          background-color: var(--background-w29);
          z-index: 2001;
          box-shadow: var(--color-h2);
          border-radius: 4px;
          border: 1px solid var(--background-w4);
          bottom: 23px;
          left: 0px;
          width: 264px;

          .drop-div {
            margin: 1px;
            height: 316px;
            background-color: var(--el-color-white);

            .list-wrap {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              overflow-x: auto;
              flex: 1;
              position: relative;

              .list-header {

                padding: 4px 12px;

                .list-cell {
                  display: flex;
                  justify-content: space-between;

                  .td-cell {
                    font-size: var(--cc-font-size-11);
                    color: var(--el-text-color-secondary);
                    padding: 4px 0;
                  }


                }
              }


              .list-cell {
                padding: 8px 12px;

                .td-cell {
                  text-overflow: ellipsis;
                  display: table-cell;
                  vertical-align: middle;
                  position: relative;

                  &:nth-of-type(1) {
                    min-width: 100px;
                  }

                  &:nth-of-type(2) {
                    text-align: center;
                    width: 70px;
                  }

                  &:nth-last-of-type(1) {
                    text-align: right;
                    width: 70px;
                  }
                }
              }

              .list-body {
                flex: 1;

                .scroll-body {
                  height: 100%;
                  height: 250px;
                  overflow-y: scroll;

                  &::-webkit-scrollbar-track {
                    // background-color: transparent !important    ;
                  }

                  ::-webkit-scrollbar-thumb {
                    background-color: #f5f5f5;
                    border-radius: 5px;
                  }

                  &::-webkit-scrollbar {
                    width: 4px;
                  }


                  /*定义内层 滑块颜色、内阴影及圆角*/
                  // &::-webkit-scrollbar-thumb {
                  //     border-radius: 6px;
                  //     width: 4px;
                  //     background-color: #629EAB;
                  // }

                  .list-view {
                    .list-cell {
                      &.active {
                        background: var(--background-w3) !important;
                      }

                      .td-cell {
                        text-overflow: ellipsis;
                        display: table-cell;
                        vertical-align: middle;

                        .symbol-name {
                          display: flex;
                          align-items: center;
                          color: var(--el-text-color-primary);

                          .market_closed {
                            width: 4px;
                            height: 6px;
                            border-left: 1px solid var(--font-icon-t3);
                            border-right: 1px solid var(--font-icon-t3);
                            display: inline-block;
                            margin-left: 2px;
                            margin-right: 4px;
                          }
                        }

                        .symbol-label {
                          font-size: var(--cc-font-size-9);
                          color: var(--el-text-color-secondary);
                        }

                        &.profit {
                          color: var(--color-p1);
                        }

                        &.loss {
                          color: var(--color-l1);
                        }
                      }
                    }
                  }
                }


              }
            }
          }
        }

        .popular_title {
          margin-right: 24px;
          color: var(--font-icon-t7);
        }

        ul {
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 24px;

        }
      }

      .footer_wrap-right {
        display: flex;
        align-items: center;

        .locale-color {
          margin: 0 20px 0 4px;
          color: var(--font-icon-t7);
        }
      }

    }
  }
}
</style>
