export default {
	common: {
		learn: {
			title1: "Keseimbangan primer",
			content1: "CXMDirects menawarkan layanan saldo uang tunai dan Hosted Cryptocurrency kami secara gratis, memungkinkan Anda untuk menyimpan uang tunai dan cryptocurrency yang didukung tanpa biaya. Anda dapat mentransfer kripto dari satu saldo utama pengguna CXMDirects ke saldo utama pengguna lain tanpa biaya apa pun.\n" +
				"\n" +
				"Biaya transaksi jaringan\n" +
				"\n" +
				"Untuk transaksi di jaringan mata uang kripto (yaitu, transfer mata uang kripto dari platform CXMDirects), CXMDirects mengeluarkan dan membayar biaya transaksi jaringan (misalnya, biaya penambang). Ketika Anda mengirim mata uang kripto dari dompet CXMDirects Anda ke dompet lain, kami akan membebankan biaya berdasarkan perkiraan biaya jaringan yang berlaku untuk pengiriman dompet-ke-dompet yang berdiri sendiri. Namun, biaya akhir yang dibayarkan CXMDirects mungkin berbeda dari perkiraan biaya karena faktor-faktor seperti transaksi batching atau perubahan tingkat kemacetan jaringan. Semua biaya diungkapkan pada saat transaksi.\n" +
				"\n" +
				"Keuntungan efisiensi dan biaya jaringan\n" +
				"\n" +
				"Karena potensi keuntungan efisiensi dari transaksi batching, jumlah agregat estimasi biaya jaringan yang dibayarkan oleh pengguna dalam batch tertentu dapat melebihi biaya jaringan akhir yang dibayarkan oleh CXMDirects.\n" +
				"\n" +
				"Biaya pemrosesan Jaringan Lightning\n" +
				"\n" +
				"Lightning Network adalah jaringan pembayaran mikro yang memungkinkan transfer bitcoin tanpa dikenakan biaya transaksi yang terkait dengan blockchain Bitcoin. Ketika Anda mengirim bitcoin dari CXMDirects melalui Lightning Network, kami akan membebankan biaya pemrosesan sebesar 0,1% dari jumlah bitcoin yang ditransfer.\n" +
				"\n" +
				"Menambah uang tunai dan menguangkannya\n" +
				"\n" +
				"Ketika Anda menambahkan uang tunai atau menarik uang tunai, Anda mungkin akan dikenakan biaya tergantung pada metode pembayaran yang Anda pilih. Penting untuk meninjau perincian biaya sebelum menyelesaikan transaksi untuk memahami biaya terkait.\n" +
				"\n" +
				"Transaksi kredit\n" +
				"\n" +
				"Jika Anda meminjam USD dari CXMDirects atau afiliasi CXMDirects dan kami harus menjual jaminan BTC Anda (sebagaimana diotorisasi dalam perjanjian pinjaman yang berlaku), kami akan membebankan biaya tetap sebesar 2% dari total transaksi.",
			title2: "Biaya dan spread trading",
			content2: "Biaya CXMDirects\n" +
				"\n" +
				"Ketika Anda membeli, menjual, atau mengonversi mata uang kripto di CXMDirects, ada biaya yang dikenakan. Biaya ini dihitung pada saat Anda melakukan pemesanan dan dapat dipengaruhi oleh faktor-faktor seperti metode pembayaran yang Anda pilih, ukuran pesanan, kondisi pasar, lokasi yurisdiksi, dan biaya lain yang kami keluarkan untuk memfasilitasi transaksi Anda.\n" +
				"\n" +
				"Sebelum mengirimkan transaksi Anda, Anda bisa melihat biaya di layar pratinjau jual beli. Harap dicatat bahwa biaya dapat bervariasi untuk transaksi serupa. Dengan menyelesaikan pesanan, Anda setuju untuk membayar biaya CXMDirects yang berlaku untuk transaksi tersebut.\n" +
				"\n" +
				"Penyebaran\n" +
				"\n" +
				"Ketika Anda melakukan order beli dan jual sederhana, CXMDirects menyertakan spread dalam harga yang dikutip. Spread juga termasuk dalam nilai tukar saat mengonversi dari satu mata uang kripto ke mata uang kripto lainnya.\n" +
				"\n" +
				"Untuk melihat spread sebelum melihat pratinjau order beli, jual, atau konversi, pada layar pratinjau:\n" +
				"\n" +
				"Order beli dan jual: ketuk keterangan alat di samping item baris harga aset Anda untuk melihat spread.\n" +
				"\n" +
				"Konversi order: ketuk keterangan alat di samping nilai tukar yang Anda tentukan.\n" +
				"\n" +
				"Spread membantu meningkatkan kemungkinan transaksi yang sukses dan memungkinkan CXMDirects untuk mengunci harga yang dikutip untuk sementara waktu saat memproses pesanan Anda. CXMDirects dapat menyimpan kelebihan spread dari suatu transaksi. Harap dicatat bahwa spread dapat bervariasi untuk transaksi serupa.\n" +
				"\n" +
				"CXMDirects Tingkat Lanjut\n" +
				"\n" +
				"Tidak ada spread yang disertakan untuk CXMDirects Advanced karena Anda berinteraksi langsung dengan buku pesanan.\n" +
				"\n" +
				"CXMDirects One\n" +
				"\n" +
				"CXMDirects One adalah produk langganan yang menawarkan pembelian dan penjualan mata uang kripto tanpa biaya, dengan batasan tertentu. Anggota mungkin masih memiliki spread yang termasuk dalam harga kuotasi mereka.\n" +
				"\n" +
				"Perubahan pada biaya trading dan spread\n" +
				"\n" +
				"CXMDirects sesekali menguji perubahan pada biaya dan spread. Perubahan ini dapat diterapkan di berbagai wilayah, aset, ukuran pesanan, dan jenis perdagangan.\n" +
				"\n" +
				"CXMDirects menampilkan jumlah total, termasuk spread dan biaya, setiap kali Anda melihat pratinjau perdagangan dan setelahnya dalam riwayat transaksi Anda. \n" +
				"\n" +
				"Eksekusi perdagangan\n" +
				"\n" +
				"Semua perdagangan dari Saldo Utama Anda dieksekusi melalui buku pesanan batas pusat CXMDirects Exchange atau lelang kompetitif. Anda dapat melihat buku pesanan pasar, tawaran, permintaan, dan kedalaman secara real-time. Buku pesanan ini menentukan harga penawaran yang Anda lihat selama perdagangan.\n" +
				"\n" +
				"CXMDirects tidak memiliki hubungan pembayaran untuk arus pesanan (PFOF) dengan pembuat pasar.",
			title3: "Kartu CXMDirects",
			content3: "Pembelanjaan dengan Kartu CXMDirects tidak dikenakan biaya transaksi. \n" +
				"\n" +
				"CXMDirects memang menyertakan spread dalam harga untuk membeli atau menjual mata uang kripto. Hal ini memungkinkan kami untuk mengunci harga sementara untuk eksekusi perdagangan sementara Anda meninjau detail transaksi sebelum mengirimkan transaksi Anda.  \n" +
				"\n" +
				"Anda mungkin akan dikenakan biaya oleh operator ATM. Silakan lihat Perjanjian Pemegang Kartu CXMDirects Anda untuk perincian lebih lanjut.",
			title4: "Layanan taruhan",
			content4: "Tidak ada biaya untuk melakukan staking atau unstaking. CXMDirects mengambil komisi berdasarkan hadiah yang Anda terima dari jaringan. Komisi standar kami adalah 35% untuk ADA, ATOM, DOT, MATIC, SOL, dan XTZ. Untuk ADA, ATOM, DOT, SOL, dan XTZ, komisinya adalah 26,3% untuk anggota CXMDirects One yang memenuhi syarat. Anda dapat menemukan tingkat komisi ETH Anda di Detail Aset. Beberapa pengguna mungkin menerima penawaran untuk promosi staking dengan tarif yang lebih rendah. APY dan hadiah yang ditampilkan di akun Anda selalu mencerminkan penghasilan yang Anda terima setelah komisi kami. Anda dapat menemukan lebih banyak tentang komisi di Perjanjian Pengguna CXMDirects.",
			title5: "Pemulihan aset",
			content5: "Jika Anda mengirim mata uang kripto yang tidak didukung ke akun CXMDirects Anda, mata uang kripto tersebut mungkin memenuhi syarat untuk pemulihan. Kami akan membebankan biaya jaringan untuk upaya pemulihan. Untuk pemulihan dengan perkiraan nilai lebih dari $100, kami juga akan membebankan biaya pemulihan sebesar 5% dari jumlah di atas $100. Perkiraan nilai pemulihan mungkin berbeda dari nilai pasar pemulihan yang sebenarnya.",
		},
		award1: "Mejor corredor de contratos diferenciales de Asia 2023",
		award2: "El mejor corredor del mundo 2022",
		award3: "El corredor más confiable de 2022",
		award4: "La marca más confiable del año 2021",
		award5: "Mejor corredor de fintech",
		award6: "Los corredores más transparentes en las transacciones",
		country: "analisis",
		health: "kesehatan",
		analysis: "negara",
		information: "informasi",
		help: "Bantuan",
		confirm: "Konfirmasi",
		cancel: "Batalkan",
		tip: "Prompt",
		place_select: "Silakan pilih",
		placeEnter: "Silakan masukkan",
		countryCode: "Kode negara",
		submit: "Kirim",
		more: "Lebih",
		deposit: "Deposit tunai",
		real_trade: "Transaksi nyata",
		paper_trade: "Transaksi Simulasi",
		build_paperTrade: "Membuat akun simulasi",
		start_trade: "Mulai perdagangan segera",
		app_name: "CXMDirects",
		success: "Sukses！",
		dataSources_name: "Trading Central",
		reset: "Reset",
		iknow: "Dapat.",
		noData: "Tidak ada data yang tersedia",
		modify: "Ubah",
		pingcang: "Posisi menutup",
		all: "Seluruh",
		tip_cardMain: "Ambil atau mengunggah foto FRONT dari kartu identitasmu",
		tip_cardBack: "Ambil atau mengunggah foto dari BAlik kartu identitasmu",
		tip_cardMain1: "Ambil atau mengunggah foto FRONT dari lisensi pengemudi Anda",
		tip_cardBack1: "Ambil atau mengunggah foto dari BAlik lisensi pengemudi Anda",
		tip_cardMain2: "Ambil atau mengunggah foto paspor Anda",
		tip_cardBack2: "Ambil atau mengunggah foto paspor Anda",
	},
    currency: {
		btcusdt: "Bitcoin",
		ethusdt: "Ethereum",
		ltcusdt: "Litecoin",
		xrpusdt: "Riak (mata uang)",
		adausdt: "Mata uang Ada (digunakan dalam pecahan mata uang)",
		eosusdt: "Koin Pomelo",
		dotusdt: "Koin Boca",
		trxusdt: "Koin medan gelombang",
		xmrusdt: "Koin Monroe (mis. Dolar AS)",
		dogeusdt: "Koin doggie",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "USD AUD",
		fx_sgbpusd: "GBP USD",
		fx_seurusd: "Euro USD",
		fx_snzdusd: "Dolar Selandia Baru (NZD) Dolar Amerika Serikat",
		fx_susdcad: "CAD USD",
		fx_susdchf: "USD CHF",
		fx_susdjpy: "USD JPY",
		fx_saudcad: "AUD CAD",
		fx_saudchf: "AUD CHF",
		fx_saudjpy: "AUD JPY",
		fx_saudnzd: "Dolar Australia Dolar Selandia Baru (NZD)",
		fx_scadchf: "Dolar Kanada franc Swiss",
		fx_scadjpy: "Dolar Kanada Yen Jepang",
		fx_schfjpy: "Yen franc Swiss",
		fx_seuraud: "Euro Dolar Australia",
		fx_seurcad: "Euro Dolar Kanada",
		fx_seurgbp: "Euro Poundsterling",
		fx_seurjpy: "Euro Yen",
		fx_seurnzd: "Euro Dolar Selandia Baru (NZD)",
		fx_sgbpaud: "Poundsterling Inggris Dolar Australia",
		fx_sgbpchf: "Poundsterling Franc Swiss",
		fx_sgbpjpy: "GBP JPY",
		fx_sgbpnzd: "Pound Dolar Selandia Baru (NZD)",
		fx_scadnzd: "Dolar Kanada Dolar Selandia Baru",
		hf_CL: "Minyak Mentah WTI New York",
		hf_OIL: "Minyak mentah Brent",
		hf_GC: "Emas New York",
		hf_SI: "New York Silver",
		hf_HG: "Tembaga (kata pinjaman)",
		hf_NG: "Gas Alam Amerika Serikat",
		hf_CAD: "Tembaga (kata pinjaman)",
		hf_AHD: "Aluminium London",
		HX_AAPL: "Delima",
		HX_BABA: "Ali Baba, karakter dari The Arabian Nights",
		HX_GOOG: "Google",
		HX_WMT: "Wal-Mart, Walmart (pengecer)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "Nikola Tesla (1856-1943), penemu dan insinyur asal Serbia",
		HX_N225: "Nikkei 225",
		HX_GDAXI: "DAX30, Jerman",
		HX_IBEX: "IBEX35, Spanyol",
		HX_SPX: "Indeks S&P 500",
		HX_FTSE: "FTSE 100",
		HX_KS11: "KOSPI Korea",
		HX_NZ50: "Selandia Baru 50",
		HX_PSI20: "Portugal PSI20",
		hf_ZSD: "London Zinc",
		hf_NID: "London Nikel",
		hf_PBD: "London memimpin",
		hf_SND: "Timah London",
		hf_XAU: "Emas London (Emas Spot)",
		hf_XAG: "London Silver (Spot Silver)",
		hf_XPT: "Platinum berjangka",
		hf_XPD: "Palladium Berjangka",
		hf_CT: "Kapas AS",
		hf_SM: "Bubuk Kacang Kedelai",
		hf_BO: "Minyak kedelai AS",
		HX_SENSEX: "SENSEX, Mumbai, India",
		HX_KLSE: "FTSE Malaysia KLCI",
		HX_KSE100: "Karachi, Pakistan",
		HX_FCHI: "CAC40, Prancis",
		HX_SX5E: "Snooker Eropa 50",
		HX_ICEXI: "ICEX, Islandia",
		HX_ASE: "ASE Athena, Yunani",
		HX_OMXC20: "OMX Copenhagen 20",
		HX_OSEBX: "OSEBX, Norwegia",
		HX_OMXSPI: "OMXSPI, Swedia",
		HX_AMD: "Chaowei Semiconductor",
		HX_DIS: "Disney (nama)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "MasterCard (merek)",
		HX_NVDA: "NVIDIA, perusahaan kartu grafis komputer",
		HX_INTC: "Intel",
		HX_GOGO: "Gogo Inc",
		HX_STX: "Teknologi Seagate",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "AstroCoin",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	currenctType: {
        title: "Silakan pilih uang",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'dolar',
        currenctType15: 'Dolar Hong Kong',
        currenctType16: 'Dollar Taiwan',
        currenctType17: 'Uang Malaysia',
        currenctType18: 'Uang baru',
        currenctType19: 'Yen Jepang',
        currenctType20: 'Euro',
        currenctType21: 'AUD',
        currenctType22: 'Indonesia Rupiah',
        currenctType23: 'GBP',
        currenctType24: 'Baht Thailand',
        currenctType25: 'Cad',
        currenctType26: 'Dong Vietnam',
        currenctType27: 'Korea menang',
        currenctType28: 'Macau Pataca',
    },
    addnew2: {
        address: {
			account_type: "Jenis Akaun",
			ifsc_code: "Kode IFSC",
            title: "Alamat penarikan",
            account_number: "Nomor Akaun",
            add_btn: "Tambah Alamat",
            label_type: "Pilih Jenis",
            place_type: "Silakan pilih tipe",
            label_number: "Nomor Akaun",
            place_number: "Silakan masukkan nomor rekening",
            wallet_address: 'Alamat dompet',
            bank_name: 'Nama Bank',
            bank_address: "Alamat bank",
            name: "Nama",
            payee_address: 'Alamat Penerima',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nama Branch",
            branch_code: "Kode Branch",
            bank_code: "Kode bank",
            phone: "Nomor ponsel",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Silakan masukkan isi",
        },
    },
	addnew: {
		paypassTit: "Ubah sandi penarikan",
		paypassTit1: "Silakan tetapkan sandi penarikan Anda",
		paypassTit2: "Kata sandi sosial awal adalah kata sandi daftar masuk",
		loan: "Pinjaman",
		repaid: "Balas",
		unpaid: "Tidak dibayar",
		loanMore: "Aku butuh pinjaman.",
		immediateRepayment: "Pembayaran segera",
		accountType1: "Akun monet",
		accountType2: "Akun kontrak",
		accountType3: "Akun opsi",
		from: "Dari",
		to: "Ke",
		get: "Akan menerima",
		labelBreed: "Valuta",
		placeBreed: "Silakan pilih uang",
		labelTransAccount: "Akaun Transfer",
		labelAmount: "Jumlah transfer",
		placeAmount: "Silakan masukkan jumlah transfer",
		transferTit: "Transfer rekening",
		convertTit: "Penukaran flash uang",
		balanceTit: "Balas rekening",
		available: "Kredit tersedia",
		pending: "Mengunci",
		equivalent: "Sama",
		coinTrade: "Penjualan uang",
		secondContract: "Kontrak kedua",
		number: "Jumlah",
		labelNumber: "Jumlah",
		placeNumber: "Silakan masukkan jumlah penebusan",
		time: "Waktu",
        loadAmount: "Jumlah pinjaman yang dijangka",
        repaymentCycle: "Siklus pembayaran pinjaman",
        dailyRate: "Rata bunga sehari",
		repaymentMethod: "Metode pembayaran",
		loanTip: "Pinjaman kredit (pastikan gambar jelas dan terlihat)",
        loanTip1: "Memuat naik sertifikat properti",
        loanTip2: "Bukti pendapatan (hubungan pekerjaan)",
        loanTip3: "Rincian pernyataan bank",
        loanTip4: "Naikkan foto dari depan kartu ID Anda",
        interest: "Bunga",
		repaymentMethod1: "Satu pembayaran setelah dewasa",
	},
	params: {
		product_foreign: "Forex",
		product_shop: "Komoditas",
		product_number: "Indeks",
		product_stock: "Saham",
		product_coin: "Coin",
		billTypes: [
			{ v: 101, name: 'Isi ulang' },
			{ v: 102, name: 'Tambah' },
			{ v: 103, name: 'Freeze' },
			{ v: 104, name: 'Meluap' },
			{ v: 105, name: 'Deposit' },
			{ v: 106, name: 'Deposit' },
			{ v: 201, name: 'Pembeku tarik' },
			{ v: 202, name: 'Deduksi' },
			{ v: 203, name: 'Pengunduran berhasil' },
			{ v: 204, name: 'Pengunduran gagal' },
			{ v: 205, name: 'Gaji penarikan' },
			{v: 206, name: 'Transfer keluar'},
			{v: 207, name: 'Transfer masuk'},
			{v: 208, name: 'Transfer uang keluar'},
			{v: 209, name: 'Transfer uang masuk'},
			{ v: 301, name: 'Deduksi deposit' },
			{ v: 302, name: 'Pengurangan biaya pengurusan' },
			{ v: 303, name: 'Kembalian deposit' },
			{ v: 304, name: 'Keuntungan' },
			{ v: 305, name: 'Kehilangan' },
			{v: 311, name: 'Opsi Pembelian'},
			{v: 312, name: 'Opsi mengembalikan'},
			{v: 313, name: 'Pembalikan Opsi'},
			{v: 314, name: 'Gaji pengurusan opsi'},
			{v: 315, name: 'Pembelian uang membeku'},
			{v: 316, name: 'Deduksi pembelian uang'},
			{v: 317, name: 'Kembali Pembelian Coin'},
			{v: 318, name: 'Pembelian Coin Diterima'},
			{v: 319, name: 'Pembelian Coin Diterima'},
			{v: 320, name: 'Penjualan uang beku'},
			{v: 321, name: 'Pengurangan penjualan uang'},
			{v: 322, name: 'Penjualan koin kembali'},
			{v: 323, name: 'Coins dijual untuk akun'},
			{v: 324, name: 'Coins dijual untuk akun'},
			{v: 325, name: 'Gaji pengendalian uang'},
			{v: 401, name: 'Tambahan mesin penambangan'},
			{v: 402, name: 'Kembali mesin pengembara'},
			{v: 403, name: 'Penghasilan mesin penambangan'},
			{v: 404, name: 'Keluar mesin pengembara'},
			{v: 405, name: 'Gaji pengurusan keluar mesin penambangan'},
			{v: 411, name: 'Pinjaman pendapatan'},
			{v: 412, name: 'Gaji pengurusan pinjaman'},
			{v: 413, name: 'Pembayaran berhasil'},
		],
	},
	message: {
		logout: "Daftar keluar dari daftar masuk",
		register_success: "Login berhasil",
		place_inner_personal: "Silakan masukkan informasi pribadi pertama",
		submit_success: "Mengirim dengan sukses！",
		copy_success: "Disalin dengan sukses！",
		copy_error: "Salinan gagal！",
		modify_success: "Bersukses diubah",
		no_balance: "Tidak cukup sisa untuk membayar deposit",
		palce_password: "Silakan masukkan sandi",
		trade_success: "Transaksi berhasil",
		placeEnterAmount: "Silakan masukkan jumlah",
		tipTit1: "Apakah Anda yakin Anda ingin membayar untuk pesanan ini",
	},
	web: {
		account: {
			menu1: "Informasi Akaun",
			menu2: "Asetku",
			menu3: "Laporan Akun",
			menu4: "Keamanan rekening",
			menu5: "Notis",
			menu6: "Pusat Aktivitas",
			menu7: "Dompetku",
			menu8: "Cash Out",
			walletAdd: "Add a new wallet",
			report: {
				history_tit: "Laporan Sejarah Transaksi",
				stream_tit: "Laporan aliran kapital",
				desc: "Silakan pilih jangkauan tanggal yang sesuai untuk mengeksport laporan akun. (Anda dapat memeriksa informasi akun dalam tahun terakhir)",
				select_date: "Pilih jangkauan tanggal",
				week: "Minggu lalu",
				month: "Bulan lalu",
				threeMonth: "Dalam tiga bulan terakhir",
				sixMonth: "Dalam enam bulan terakhir",
				time: "Waktu",
				view_report: "Tampilkan Laporan",
				email_send: "Kirim melalui email",
				dialog_tit: "Laporan Akun",
				dialog_p1: "CXMDirects Holding Ltd terdaftar di Pulau Cayman (nomor SIB 1612446) dan memproses transaksi Anda sebagai subjek transaksi.",
				tit2: "Laporan Sejarah Transaksi Mitade",
				tit4: "Laporan Aliran Dana Mitade",
				tit3: "Informasi Akaun",
				label1: "title of account",
				label2: "ID Akaun",
				label3: "Uang Akun",
				label4: "Waktu laporan",
				label5: "Periode laporan",
				label6: "Aliran kapital",
				empty: "Anda tidak memiliki perdagangan tertutup",
				email_des: "Laporan akan dikirim ke email Anda",
				tab1: "Laporan",
				tab2: "Pernyataan harian",
				tab3: "Pernyataan bulanan",
			},
			account: {
				tit1: "Selamat datang di CXMDirects",
				tit2: "Selesaikan verifikasi identitas untuk melanjutkan transaksi deposit nyata",
				p1: "Membuka akun transaksi nyata",
				p2: "Otentikasi",
				btn1: "Otentikasi",
				tit3: "Informasi pribadi",
				label_name: "Nama",
				label_email: "E-mail",
				label_address: "Alamat residen",
				tit4: "Informasi Akaun",
				label_account: "Akaun perdagangan (transaksi nyata)",
				label_currency: "Uang dasar (transaksi nyata)",
				label_company: "Perusahaan pembukaan rekening saat ini",
				company_tip: "CXMDirects Holding Ltd, perusahaan di bawah Grup CXMDirects, telah mendapatkan izin dan otorisasi dari Kepulauan Cayman Monetary Authority (CIMA).",
				tit5: "Membuka akun transaksi nyata",
				tit6: "Perbaiki informasi",
				tit7: "Isi informasi dasar Anda",
				tit8: "Penilaian risiko",
				tit9: "Menghargai toleransi risiko perdagangan",
				tit10: "Otentikasi",
				tit11: "Selesaikan verifikasi identitas sesuai dengan kebutuhan regulasi",
				p3: "Silakan pilih salah satu dokumen dukungan berikut untuk verifikasi",
				type1: "Kartu ID",
				type2: "Lesen pengemudi",
				type3: "Pasport",
				p4: "Saya mengkonfirmasi bahwa semua informasi dan dokumen yang diberikan adalah lengkap, benar, dan akurat jika ada pembaruan informasi, saya berjanji untuk memberikan informasi terbaru kepada CXMDirects dalam 30 hari.",
				next: "Langkah berikutnya",
				btn2: "Disertifikasikan",
				modify_personal_tit: "Ubah informasi pribadi",
			},
			activity: {
				tit: "Pusat Aktivitas",
				desc: "Anda dapat berpartisipasi dalam aktivitas untuk menerima hadiah dan meningkatkan pendapatan investasi Anda",
			},
			capital: {
				real_trade: "Transaksi nyata",
				label_nowAccount: "Akaun saat ini",
				label_tradeAccount: "Akaun perdagangan",
				label_currency: "Uang dasar",
				deposit_withdraw_record: "Catatan deposit dan penarikan",
				capital_flow_details: "Rincian aliran kapital",
			},
			notification: {
				tit: "Pengaturan Notifikasi",
				tit1: "Buka saluran pemberitahuan",
				email: "E-mail",
				message: "Pesan",
				push: "Dorong",
				verify: "Validate",
				verified: "Diverifikasi",
				p1: "Silakan buka/verifikasi saluran pemberitahuan berikut, pesan penting tidak terlepas!",
				type1: "Pemasaran",
				tit2: "Notifikasi Kejadian Pemasaran",
				desc2: "Informasi promosional platform, aktivitas operasional, dan pesan lainnya",
				tit3: "Instruksi operasi",
				desc3: "Material pengetahuan kaya dan profesional",
			},
			security: {
				tit: "Sandi",
				isSet: "Set",
				set: "Untuk ditetapkan",
				verify: "Validate",
				verified: "Diverifikasi",
				tit1: "Verifikasi keamanan",
				email: "E-mail",
				phone: "Nomor ponsel",
				phone_tit: "Pengikatan nomor telepon",
				password_tit: "Ubah kata sandi",
			},
		},
		market: {
			hot: "Popular",
			prev: "Halaman terakhir",
			next: "Halaman berikutnya",
			line: "Pembahagian waktu",
			main_subplot: "Indikator gambar utama dan bantuan",
			main: "Indikator gambar utama",
			subplot: "Indikator gambar sekunder",
			go_trade: "Jump ke halaman transaksi",
		},
		news: {
			tip: "Penolakan: Informasi di atas adalah umum dan hanya untuk referensi, dan tidak digunakan sebagai dasar untuk keputusan perdagangan.",
		},
		trade: {
			state1: "Posisi memegang",
			state2: "Mendaftar",
			state3: "Sejarah",
			state: "Keadaan",
			state4: "Posisi tertutup",
			state5: "Dibatalkan",
			type: "Tipe",
			type1: "Seluruh",
			type2: "Jual",
			type3: "Beli",
			left_label1: "Pilihan sendiri",
			left_label2: "Tampilan Baru-Baru",
			left_type1: "Mod Kolom Kompak",
			left_type2: "Mod kolom bebas",
			left_type3: "Tampilkan grafik trend",
			all: "Seluruh",
			right_time: "Waktu kemaskini",
			right_bp: "Diterima kemarin",
			right_sp: "Koleksi hari ini",
			right_height: "Tertinggi",
			right_low: "Minimum",
			remind_tit: "Peringatan Baru",
			remindList: "Senarai Pengingatan",
			remind_btn: "Peringatan Baru",
			right_tab1: "Transaksi",
			right_tab2: "Rincian",
			right_sell: "Jual",
			right_buy: "Beli",
			right_type: "Tipe",
			right_type1: "Pasar ",
			right_type2: "Pending ",
			right_priceMoney: "Angkat harga unit",
			right_pointerNumber: "Jumlah (tangan)",
			right_lever: "Lever",
			right_tip: "Deposit diperlukan",
			right_balance: "Kesimbangan yang tersedia",
			right_stop_profit: "Hentikan surplus",
			right_stop_loss: "Hentikan kehilangan",
			right_profit_loss: "Profit dan kehilangan",
			now_order: "Tempatkan perintah segera",
			confirm_order: "Konfirmasi Perintah",
			right_tit1: "Strategi perdagangan",
			right_rinei: "Dalam siang hari",
			right_short: "Jangka pendek",
			right_middle: "Pertengahan jangka",
			right_tit2: "Strategi Alternatif",
			right_tit3: "Revisi Teknik",
			right_tit4: "Data",
			right_tit5: "Sentimen perdagangan",
			right_label_sell: "Penjual",
			right_label_buy: "Pembeli",
			right_tip2: "Kemaskini setiap 15 menit",
			right_tit6: "Penukaran harga",
			minute: "Menit",
			today: "Hari ini",
			right_tit7: "Jarak variasi harga",
			now_price: "Harga saat ini",
			right_low_price: "Harga paling rendah",
			right_height_price: "Harga maksimum",
			right_tit8: "Informasi Kontrak",
			right_tit9: "AUD ke CHF",
			right_label1: "Jumlah transaksi tunggal",
			right_label2: "Maksimum leverage",
			right_label3: "Ukuran kontrak",
			right_label4: "Maksimum jumlah posisi total",
			right_label5: "Perbedaan harga yang mengambang",
			right_label6: "Hadiah sepanjang malam",
			hand: "Tangan",
			right_collection_time: "Waktu koleksi",
			right_tip3: "Basa tagihan: dimuatkan dari seluruh ukuran transaksi",
			right_label7: "Tingkat Margin",
			right_label8: "Jaga nisbah margin",
			right_label9: "Periode perdagangan",
			right_label10: "Periode perdagangan saat ini",
			right_label11: "Periode perdagangan berikutnya",


		},
	},
	home: {
		banner_p1: "Adopsi",
		banner_p2: "Transaksi platform",
		banner_p3: "Penukaran asing, komoditas, indeks, saham",
		banner_p4: "Dan produk keuangan yang lebih populer",
		banner_p5: "Anda dapat mengeksplorasi pasar keuangan paling panas di dunia. CXMDirects menawarkan nol komisi, perbedaan harga rendah yang sangat berguna, dan konfigurasi leverage fleksibel.",
		hot: "Popular",
		tit1: "Transaksi Lebih Teman",
		p1: "Antaramuka sederhana dan intuitif untuk operasi mudah",
		p2: "Membantu halaman web, aplikasi bergerak, dan desktop, dengan transaksi di ujung jari Anda",
		p3: "Alat manajemen risiko seperti menghentikan kehilangan/pelacakan menghentikan kehilangan",
		p4: "Beberapa grafik teknis dan kalendar keuangan, berita waktu nyata",
		p5: "E-mail, SMS, dan tekan notifikasi waktu nyata",
		p6: "Terus meningkat untuk mencapai pengalaman perdagangan yang lebih baik",
		btn1: "Menjelaskan platform kita",
		row3_tit: "Perdagangan bebas kapanpun, dimanapun",
		row3_tip: "Membantu halaman web, aplikasi bergerak, dan desktop, dengan transaksi di ujung jari Anda",
		row3_tit2: "Pemindaian Kode Download",
		row3_tit3: "DesktopComment",
		row3_tit4: "Akhir ponsel",
		regular_tit: "Platform internasional yang dapat dipercaya",
		regular_tip: "Kami berkomitmen untuk menyediakan pelanggan lingkungan perdagangan yang aman dan bertanggung jawab。",
		regular_tit1: "Otoritas regulator",
		regular_p1: "Pengawalan dan otorisasi industri, memenangkan kepercayaan pelanggan global",
		regular_tit2: "Melindungi Keamanan Keuangan",
		regular_p2: "Deposit klien retail terpisah dideposisi dalam rekening kepercayaan seperti yang diperlukan oleh peraturan regulator",
		regular_tit3: "Perlindungan keseimbangan negatif",
		regular_p3: "Kami memberikan Anda perlindungan keseimbangan negatif untuk akun Anda, dan keseimbangan negatif dibersihkan ke nol dalam waktu yang tepat, memastikan bahwa kerugian Anda tidak melebihi jumlah masukan dan perdagangan dengan tenang pikiran",
		regular_tit4: "5 * 24 jam pelanggan pelanggan",
		regular_p4: "Tim pelayanan klien yang jujur dan profesional dengan dukungan online 24 jam bersedia untuk memecahkan masalah Anda",
		service_tit: "Lebih banyak layanan untuk membantumu",
		service_tit1: "Strategi perdagangan",
		service_p1: "Strategi perdagangan waktu nyata membantu Anda memahami trends pasar terbaru dan mengambil kesempatan perdagangan lebih baik.",
		service_tit2: "Pengetahuan perdagangan",
		service_p2: "Belajar perdagangan pengetahuan gratis dengan CXMDirects untuk meningkatkan kemampuan perdaganganmu.",
		service_tit3: "Manajemen resiko",
		service_p3: "Belajar tentang alat pengurusan risiko gratis CXMDirects untuk melindungi asetmu lebih baik.",
		step_tit: "Membuka akun sederhana dan nyaman",
		step_tip: "Tiga langkah sederhana untuk membuka rekening dalam beberapa menit",
		step_tit1: "Register",
		step_p1: "Isi informasi dan kirim aplikasi Anda",
		step_tit2: "Deposit tunai",
		step_p2: "Beberapa cara untuk cepat depositasi dana",
		step_tit3: "Mulai perdagangan",
		step_p3: "Menjelaskan kesempatan perdagangan dan menempatkan perintah cepat",
		award_tit: "Nilai kita",
		award_p1: "Kami selalu mengejar keajaiban dan keajaiban, berkomitmen untuk menyediakan pelanggan layanan perdagangan berkualitas tinggi.",
		award_p2: "Merek CXMDirects sangat terhormat untuk menerima penghargaan dari institusi terkenal di industri, mengakui usaha dan komitmen tim untuk pelanggan.",
		row7_tit: "Informasi keuangan global waktu reali",
		row7_tit1: "Berita waktu nyata",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "Klik untuk mengunduh",
		p1: "Penjualan kapan saja, di mana saja",
		p2: "Transaksi yang lebih cepat dan cerdas",
		p3: "0 komisi transaksi",
		p4: "Variasi investasi paling panas di dunia",
		renderDom1: "Pertukaran asing",
		renderDom2: "Aksi AS",
		renderDom3: "Emas",
		renderDom4: "Minyak mentah",
		renderDom5: "Indeks",
		renderDom6: "Kryptocurrence",
		login: "Login",
		elail: "Kotak surat",
		phone: "Nomor ponsel",
		no_account: "Tidak ada rekening？",
		go_register: "Pergi untuk mendaftar",
		register: "Register",
		now_register: "Daftar Sekarang",
		have_account: "Akaun yang ada？",
		go_login: "Pergi log masuk",
	},
	about: {
		serviceFee: "Service charge",
		introduction: "Perkenalan Platform",
		trade_platform: "Platform perdagangan",
		complaint_process: "Proses keluhan",
		page: "Halaman",
		place_search: "Cari apa yang ingin kau ketahui",
		no_answer: "Tidak dapat menemukan jawaban yang Anda butuhkan?",
		contact_us: "Hubungi Kami",
		awards: {
			h1: "Hadiah dan Kehormatan",
			p1: "Kami selalu mengejar keajaiban dan keajaiban, berkomitmen untuk menyediakan pelanggan layanan perdagangan berkualitas tinggi. Merek CXMDirects sangat terhormat untuk menerima penghargaan dari institusi terkenal di industri, mengakui usaha dan komitmen tim untuk pelanggan.",
			p2: "Atau segera mengalami platform perdagangan online yang menang"
		},
		charges: {
			h1: "Hadiah dan hadiah",
			p1: "Sebagai komponen dari meningkatkan pengalaman pelanggan, CXMDirects berharap untuk menyediakan pelanggan dengan layanan perdagangan yang lebih efektif pada biaya. Kami berkomitmen untuk menjaga keseluruhan dan transparansi dalam biaya dan biaya, mengecualikan biaya tersembunyi. Biaya transaksi utama untuk pelanggan adalah pembebasan tawaran dan bunga overnight pada posisi mereka.",
			h2: "Pertama, Anda dapat menikmati",
			li1: "Quotasi produk waktu nyata",
			li2: "Indikator teknis profesional",
			li3: "Analisi pasar eksklusif",
			free: "Bebas",
			view: "Lihat pada",
			h3: "Bid-Ask Spread",
			p2: "CXMDirects membayar layanannya dengan menuntut perbedaan harga tawaran-minta. Bagian dari biaya ini telah direfleksi dalam kwotasi produk, dan ketika Anda menetapkan posisi, Anda benar-benar membayar perbedaan harga. Karena biaya perbedaan harga tidak tetap, kami sarankan Anda pergi ke halaman kvotasi produk spesifik atau log masuk ke",
			h4: "Deposit dan biaya penarikan",
			p3: "Untuk mengurangi biaya pelanggan, kami tidak akan menuntut Anda untuk deposit dan penarikan Anda dalam kebanyakan kasus. Tapi perusahaan pihak ketiga atau bank dapat meminta biaya pengurusan seperti biaya bank intermedier selama proses deposit dan penarikan Anda",
			p4: "Untuk situasi di mana biaya harus ditanggung, Anda dapat memeriksa dengan spesialis layanan pelanggan kami。",
			p5: "Contohnya, melalui transaksi kartu kredit internasional, transfer rekening bank, atau transaksi dalam valuta yang tidak didukung (konversi valuta asing), dll.",
			h5: "Biaya pemegang sepanjang malam",
			p6: "Jika Anda masih memegang posisi pada pukul 10 sore Greenwich Mean Time (GMT 22:00), Anda akan ditanggung/deposit biaya overnight. Untuk informasi tentang biaya overnight untuk setiap produk, Anda dapat pergi ke halaman kvotasi produk spesifik atau log masuk ke",
			h6: "Biaya lainnya",
			p7: "Tidak ada biaya lain. Kebijakan tuntutan kami benar-benar transparen, dan setiap barang yang memerlukan tuntutan akan diumumkan dan diberitahu secara awal, tanpa khawatir tentang tuntutan implicit."
		},
		contact: {
			h1: "Hubungi Kami",
			address: "Alamat",
			custom_email: "E-mail kontak klien",
			business_email: "Email kerjasama bisnis",
			h2: "Tulis surat",
			p1: "Jika Anda memiliki pertanyaan tentang layanan kami, silakan isi formulir berikut untuk mengajukan pertanyaan, dan spesialis layanan pelanggan kami akan menjawab secepat mungkin. Selain itu, jika Anda ingin mendaftar untuk strategi perdagangan sehari-hari yang disediakan oleh kolaborasi kami dengan Trading Central, silakan pilih 'Tanggal ke Daily Market Analysis' dalam tipe tabel berikut dan isi versi bahasa yang Anda ingin menerima. Kami akan mendaftar untuk Anda* Layanan penandatanganan hanya berlaku untuk pelanggan nyata dari CXMDirects yang ada.",
			h3: "Prosedur panggilan",
			p2: "Kami akan memiliki tim dedikasi untuk menyelidiki dan memecahkan semua keluhan untuk Anda.",
		},
		faq: {
			h1: "Pertanyaan sering Ditanyakan",
			tit1: "Apa gunanya akun simulasi？",
			tit2: "Bagaimana membuka akun nyata？",
			tit3: "Bagaimana membuat daftar harga pasar？",
			tit4: "Mengapa aku tidak bisa menempatkan perintah？",
			p1: "Akun simulasi dan akun nyata kebanyakan sama dalam terma antarmuka transaksi, data, dan operasi. Akun simulasi mengandung $ 50000 dalam dana virtual, dengan tujuan untuk memperkenalkan pelanggan dengan berbagai fungsi platform melalui operasi simulasi tanpa risiko keuangan apapun.",
			p2: "Silakan ikuti langkah berikut untuk membuka akun nyata:",
			p3: "1. Masukkan halaman pendaftaran CXMDirects dan ikuti prompt: daftar nomor email/telepon, tetapkan sandi daftar masuk, dan secara otomatis membuat 'akun simulasi' setelah mengirimkan konfirmasi.",
			p4: "2.Di halaman akun simulasi, pilih 'Beralih ke Akun Real' dan ikuti proses untuk membuka 'Akun Real'.",
			p5: "Sebelum membuka akun nyata, kami menyarankan bahwa Anda membaca dan memahami dokumen penemuan hukum yang berhubungan dengan transaksi.",
			p6: "Silakan perhatikan bahwa Anda hanya dapat mendepositkan dana ke akun asli Anda setelah menyelesaikan verifikasi identitas.",
			p7: "Klik pada 'Perdagangan' akan menampilkan semua produk yang platform dapat menawarkan untuk perdagangan. Anda dapat memasukkan kode variasi atau nama dalam kotak masukan di sebelah kanan untuk mencari, memilih produk, dan klik pada 'Sell/BUY' untuk muncul jendela perdagangan. Di jendela perdagangan, Anda dapat melihat harga saat ini dan margin yang diperlukan. Anda dapat secara manual menyesuaikan jumlah perdagangan terbuka dan menetapkan keuntungan berhenti dan menghentikan kehilangan untuk mengendalikan risiko, Klik pada tombol 'Sell/BUY' di bawah untuk menyelesaikan proses pembukaan. Silakan perhatikan bahwa semua harga produk keuangan terus-menerus berkembang dan diperbarui dengan pasar, dan pasar mungkin telah berubah sebelum Anda menekan tombol membeli/menjual",
			p8: "Pertama, tolong periksa apakah koneksi jaringan normal. Ketika pasar varietas perdagangan secara sementara ditutup atau ditutup, itu akan menyebabkan ketidakmampu untuk menempatkan perintah. Untuk informasi lebih lanjut, tolong hubungi layanan pelanggan online kami untuk bantuan lebih lanjut.",

		},
		fundSecurity: {
			h1: "Keamanan keuangan",
			p1: "CXMDirects diijinkan dan diatur oleh agensi regulator yang berwenang. Penyesuaian adalah inti dan budaya setiap tugas yang kita lakukan. Keamanan keuanganmu sangat penting, karena semua deposit pelanggan terpisah dan disimpan.",
			p2: "Deposit pelanggan retail terpisah dideposisi dalam akun kepercayaan ketika diperlukan sesuai dengan keperluan regulasi",
			p3: "CXMDirects tidak menggunakan dana pelanggan untuk aktivitas komersial apapun diluar izin hukum dan regulasi.",
			p4: "CXMDirects tidak akan terlibat dalam aktivitas spekulatif di pasar apapun",
			p5: "Audit independen oleh perusahaan akuntansi pihak ketiga",
		},
		help: {
			h1: "Selamat datang di Pusat Bantuan CXMDirects",
		},
		media: {
			h1: "Pusat Media",
			p1: "Lihat informasi, iklan pers, ciri-ciri media, pengumuman, dan informasi lainnya yang berhubungan dengan merk CXMDirects.",
		},
		mitrade: {
			h1: "Tentang CXMDirects",
			view_certificate: "（Metode verifikasi）",
			p1: "CXMDirects adalah perusahaan teknologi keuangan yang diijinkan dan diatur oleh institusi otoritas, fokus pada menyediakan investor pengalaman perdagangan sederhana dan nyaman. Platform perdagangan berbagai macam CXMDirects inovatif telah berulang kali memenangkan penghargaan seperti platform perdagangan ponsel terbaik dan perusahaan securities yang paling inovatif.",
			p2: "Melalui platform CXMDirects, Anda dapat investasi dan perdagangan dalam jangkauan yang lebih luas pasar keuangan internasional, meliputi ratusan varietas popular seperti saham, indeks, komoditas, dan pertukaran asing. Bukan hanya terbatas pada platform perdagangan ponsel dan web yang ramah bagi pengguna, CXMDirects juga memberikan Anda biaya transaksi kompetitif, eksekusi transaksi cepat, layanan pelanggan yang hebat, dan dukungan informasi yang kaya, membantu Anda mengambil investasi dan kesempatan perdagangan dalam waktu yang tepat",
			p3: "CXMDirects terkendali secara ketat oleh otoritas industri dan beberapa institusi. CXMDirects International diijinkan dan diatur oleh Komisi Layanan Finanzial Mauritius (FSC) dengan nomor lisensi GB20025791. Untuk informasi lebih lanjut di piring lisensi Mauritius, silakan kunjungi situs resmi FSC https://www.fscmauritius.org",
			p4: "Lakukan pertanyaan. MCXMDirects Global memiliki Lesen Layanan Finanzial (AFSL 398528) yang diijinkan oleh Australia Securities and Investment Commission (ASIC). Untuk lebih rincian pada plat lisensi Australia, silakan kunjungi situs resmi ASIC di www.asic.gov.au",
			p5: "Lakukan pertanyaan. ITrade Holding diijinkan dan diatur oleh Kepulauan Monetar Authority (CIMA) dengan nomor SIB 1612446. Untuk lebih rincian pada plat lisensi Cayman, silakan kunjungi situs resmi CIMA di www.cima.ky",
			p6: "Lakukan pertanyaan. Semua operasi bisnis CXMDirects dilakukan di bawah pengawasan ketat dan mematuhi semua peraturan.",
			h2: "Latar belakang CXMDirects",
			p7: "CXMDirects adalah tim senior dengan pengalaman dan pengetahuan kaya dalam perdagangan keuangan dan industri fintech, yang didirikan di Melbourne, Australia. Filosofi CXMDirects adalah untuk membuat transaksi lebih mudah dan lebih ramah",
			p8: "Strategi integrat dan model bisnis kami memungkinkan kita memiliki pengamatan dan pemahaman yang meliputi dan mendalam pengembangan industri dan permintaan pasar, memungkinkan kita untuk mengalokasi sumber daya yang lebih ditujukan, terus-menerus innovasi teknologi dan optimisasi efisiensi, dan terus-menerus membawa pelanggan pengalaman perdagangan yang lebih nyaman dan ramah. / Ya.",
			h3: "Mengapa memilih CXMDirects",
			why1: "Kondisi perdagangan batas rendah",
			why2: "Dipengawasi oleh pihak berwenang industri",
			why3: "Platform perdagangan sederhana dan intuitif",
			why4: "Dukungan online tingkat tinggi",
			why5: "Biaya transaksi kompetitif",
			why6: "Perlindungan keseimbangan negatif",
			h4: "Hadiah dan kehormatan",
			p9: "Kami selalu mengejar keajaiban dan keajaiban, berkomitmen untuk menyediakan pelanggan layanan perdagangan berkualitas tinggi.",
			p10: "Merek CXMDirects sangat terhormat untuk menerima penghargaan dari institusi terkenal di industri, mengakui usaha dan komitmen tim untuk pelanggan.",
			new_add: "Pengguna Baru Global",
			ol1: "Penyediakan terbaik berita dan informasi analitis",
			ol2: "Perusahaan Securities Exchange Terbaik Asia",
			ol3: "Penghargaan Kesatilan Klien Pertukaran Luar Negeri Australia",
			ol4: "Aplikasi Mobil Terbaik",
			ol5: "Perusahaan jaminan yang tumbuh paling cepat di Australia",
			h5: "Filosofi operasi CXMDirects",
			tip_tit1: "Tanggung jawab",
			tip_tit2: "Sederhana namun indah",
			tip_tit3: "Transparent",
			tip_tit4: "Inovasi",
			tip_p1: "Semua transaksi melibatkan risiko, dan CXMDirects mengambil lebih banyak tindakan untuk menyediakan pelanggan lingkungan perdagangan yang bertanggung jawab. CXMDirects subjek otoritas industri, pengawasan ketat dari berbagai institusi, dan mematuhi standar operasi keselamatan tinggi dan stabilitas. Platform kita terus menyediakan banyak alat perdagangan bertanggung jawab, termasuk manajemen resiko.",
			tip_p2: "Kesederhanaan adalah keindahan, dan rincian mencapai kecantikan CXMDirects berusaha untuk setiap rincian kesederhanaan, mudah digunakan, dan pengalaman pengguna. Kami terus memperbaiki platform dan layanan kami, berharap bahwa semua orang, baik pemula maupun investor yang mengalami pengalaman, dapat dengan mudah mengambil kesempatan perdagangan melalui platform CXMDirects.",
			tip_p3: "Untuk memberikan keuntungan penuh kepada pelanggan kita dari lingkungan perdagangan yang adil dan efisien, CXMDirects mengikuti sistem pengaturan diri yang ketat dan transparan, menyediakan informasi platform komprensif dan transparan produk, lingkungan penghargaan, dan data Semua biaya jelas dan transparan, memastikan keseluruhan jujur dan transparan dari seluruh proses layanan.",
			tip_p4: "CXMDirects sangat percaya bahwa kemajuan teknologi, inovasi keuangan, dan layanan yang lebih luas adalah kekuatan mendorong untuk pertumbuhan, memungkinkan penciptaan dan berbagi nilai terus menerus. Untuk alasan ini, kami mendorong pemikiran terbuka, terus menerus mempromosikan inovasi, dan bertujuan menjadi inovator dan pemimpin dalam teknologi keuangan.",
			row6_tit1: "Misi kita",
			row6_tit2: "Visi kita",
			row6_tit3: "Kesempatan pekerjaan",
			row6_p1: "Untuk menyediakan lingkungan perdagangan yang adil, efisien dan transparan yang tidak terbatas oleh ruang, waktu, atau pasar untuk lebih banyak orang yang ingin melakukan transaksi, memimpin inovasi keuangan dan teknologi industri.",
			row6_p2: "Kami percaya bahwa kemajuan teknologi, inovasi keuangan, dan layanan yang diversifikasi adalah kekuatan yang mendorong pertumbuhan kita, memungkinkan kita terus-menerus menciptakan dan berbagi nilai dalam masyarakat.",
			row6_p3: "Untuk tujuan ini, kami mendorong pemikiran terbuka, mempromosikan inovasi, dan bertujuan untuk menjadi",
			row6_p4: "Perusahaan pertukaran asing utama global",
			row6_p5: "Kami adalah tim global yang meliputi 7 negara, dan dengan perkembangan cepat tim, kami telah mencari bakat profesional untuk bergabung dengan kami. Bekerja dengan tim multikultur untuk melakukan apa yang Anda nikmati!",
			row6_p6: "Silakan email kami resume terbaru Anda dan alasan mengapa Anda ingin bekerja dengan kami:",
			row6_p7: "Selain itu, Anda juga dapat memeriksa",
			row6_p8: "Atau",
			row6_p9: "Lihat tempat kosong yang tersedia publik di。",
			row6_p10: "CXMDirects menjaga keamanan informasi pribadimu. Selama proses aplikasi, CXMDirects tidak akan meminta informasi keuangan darimu. Rekrutasi kami tidak membutuhkan apa pun yang berhubungan dengan keuangan, kartu kredit, informasi rekening bank, atau bentuk pembayaran apapun.",
			row6_p11: "Jika Anda mencurigai perilaku palsu di posisi tertentu, silakan hubungi",
			row6_p12: "Hubungi departemen sumber daya manusia CXMDirects. Jika Anda mengkonfirmasi bahwa Anda telah ditipu oleh informasi rekrutan palsu, silakan menghubungi departemen institusi lokal relevantmu.",

		}
	},
	product: {
		row2: {
			tit: "Variasi perdagangan",
			sell: "Jual",
			buy: "Beli",
			trade: "Transaksi",
			more_product: "Lebih banyak produk perdagangan",
		},
		row3: {
			tit: "Dukungan transaksi berbilang perangkat",
			tit1: "Dukungan transaksi berbilang perangkat",
			p1: "CXMDirects memberikan Anda platform perdagangan web, mobil, dan desktop. Kau bisa bertukar kapan saja, di mana saja.",
			download: "Download",
		},
		row4: {
			tit: "Pilih CXMDirects",
			tit1: "Platform sederhana dan intuitif",
			p1: "Integrasi pasar, perdagangan, manajemen rekening, informasi, dan manajemen resiko, memiliki fungsi yang kuat untuk mencapai pengalaman pengguna yang lebih baik",
			tit2: "Biaya transaksi kompetitif",
			p2: "0 komisi transaksi, sangat kompetitif dan transparan perbedaan harga rendah, rendah bunga overnight, memungkinkan Anda menikmati transaksi harga rendah",
			tit3: "Kondisi perdagangan batas rendah",
			p3: "Bilangan perdagangan minimal per transaksi dapat sama rendah dengan 0,01, dan margin rendah dapat digunakan untuk pembukaan perdagangan",
			tit4: "Di bawah pengawasan otoritas",
			p4: "Otorisasi otorisasi dan pengawasan di industri, dengan semua deposit pelanggan terpisah dan disimpan secara terpisah untuk melindungi keselamatan aset pelanggan",
			tit5: "Perlindungan keseimbangan negatif",
			p5: "Dalam kondisi pasar apapun, kehilangan akun Anda tidak akan melebihi jumlah utama, dan saldo negatif akan dibersihkan dalam waktu yang tepat untuk meningkatkan kemampuan manajemen risiko",
			tit6: "Dukungan online tingkat tinggi",
			p6: "Menyediakan respon cepat melayani pelanggan online, kami akan menyediakan layanan kualitas lebih tinggi melalui usaha tanpa batas tim profesional",
		},
		row5: {
			tit: "Masalah yang berhubungan",
		},
		commodities: {
			title: "Perdagangan barang-barang",
			p: "Logam berharga, energi dan barang-barang lainnya dianggap keduanya mampu melawan inflasi dan memiliki kesempatan untuk menghargai investasi, membuat mereka komponen penting dari investasi yang diversifikasi. Di antara mereka, variasi perdagangan emas, perak, minyak dan barang-barang lainnya memiliki volum perdagangan besar, dengan harga terbuka dan transparan dan likiditas tinggi, yang telah diterima secara luas oleh investor. Harga komoditas berbeda dengan signifikan karena faktor seperti persediaan dan permintaan, situasi ekonomi dan kebijakan, dan uang, menyediakan kesempatan untuk kembalian risiko.",
		},
		forex: {
			title: "Transaksi pertukaran asing",
			p: "Perdagangan pertukaran luar negeri merujuk konversi satu mata uang ke yang lain, dan pasar pertukaran luar negeri adalah pasar keuangan volum perdagangan terbesar di dunia. Ada banyak peserta di pasar pertukaran asing, yang membuat pembayaran, hedge terhadap fluktuasi mata uang, atau terlibat dalam transaksi untuk mendapatkan kembali. Perdagangan pertukaran asing dilakukan melalui internet diantara bank, institusi, dan pedagang individu di seluruh dunia, dengan fluktuasi harga terjadi 24 jam sehari, 5 hari seminggu, menyediakan kesempatan untuk kembalian risiko.",
		},
		indices: {
			title: "Indices",
			p: "Indeks saham adalah nilai statistik yang mewakili tipe spesifik saham karakteristik pada sebuah perdagangan spesifik, yang merefleksikan tingkat harga umum dan perubahan dari tipe tertentu saham konstitusi di pasar. Indeks saham perdagangan mencerminkan kesempatan investasi di pasar atau industri keseluruhan, yang dapat mengurangi resiko investasi dalam saham individu. Indeks paling populer di Amerika Serikat, Eropa, Asia dan pasar Australia, seperti Australia 200, teknologi AS 100, Hong Kong 50, Jerman 30, dll., menyediakan kesempatan yang berbeda untuk pasar saham global.",
		},
		shares: {
			title: "Penjualan saham",
			p: "Stok adalah jenis sekuriti yang dikeluarkan pada pertukaran. Untuk mengumpulkan dana, perusahaan akan mengalokasikan kepemilikan perusahaan melalui sekuriti melalui pertukaran tertulis. Pada tahun 2019 saja, jumlah perdagangan saham dunia melebihi 60 triliun dolar, dengan skala besar dan likiditas yang kuat. Di platform CXMDirects, para pedagang dapat menggunakan leverage untuk perdagangan perubahan harga saham. Harga saham dipengaruhi oleh berbagai faktor seperti faktor makroekonomi, prospek industri, dan operasi perusahaan, dengan volatilitas tinggi dan kesempatan untuk investasi dan perdagangan setiap saat.",
		},
		coins: {
			title: "Mata uang kripto",
			p: "Mata uang kripto adalah mata uang digital yang dibuat melalui kode. Mata uang ini beroperasi secara otonom di luar sistem perbankan dan pemerintahan tradisional. Seperti namanya, mata uang kripto menggunakan enkripsi untuk mengamankan transaksi dan memungkinkan pembuatan unit lain, dengan Bitcoin sebagai mata uang kripto yang asli dan sejauh ini merupakan mata uang kripto yang paling terkenal, dibuat oleh Satoshi Nakamoto dan diluncurkan pada Januari 2009. Bahkan hingga saat ini, masih belum diketahui apakah nama Satoshi Nakamoto merujuk pada seseorang atau sekelompok orang. Bitcoin dianggap sebagai mata uang kripto terdesentralisasi pertama. Seperti semua mata uang kripto lainnya, mata uang ini dikontrol melalui database transaksi blockchain yang berfungsi sebagai buku besar publik yang terdistribusi. Hingga saat ini, ada lebih dari 1.000 mata uang kripto yang tersedia untuk diperdagangkan secara online."
		}
	},
	trade: {
		data_sources: "Sumber data",
		tip: "Informasi di atas hanya untuk referensi. CXMDirects tidak menjamin akurasi, waktu nyata, dan keseluruhan isi informasi, sehingga Anda tidak harus terlalu bergantung pada informasi yang diberikan. Informasi ini tidak termasuk catatan harga transaksi kita, atau tawaran atau permintaan untuk transaksi instrumen keuangan. CXMDirects bukan perusahaan yang menyediakan nasihat keuangan, dan hanya menyediakan layanan dalam alami instruksi eksekutif. Kami sarankan pembaca mencari nasihat investasi relevan sendiri. Silakan merujuk kepada penolakan lengkap kita.",
		tip2: "Dikemaskini setiap Jumat pukul 15:00 (GMT)",
		tip3: "Perspektif perdagangan adalah alat untuk memprediksi sentimen perdagangan pasar, merefleksikan pandangan jangka pendek dan jangka tengah ahli industri dan prediksi trend pada berbagai produk populer.",
		analysis: {
			tit: "Strategi perdagangan",
			all: "Seluruh",
			day: "Dalam siang hari",
			tit1: "Strategi perdagangan",
			tit2: "Revisi Teknik",
		},
		calendar: {
			tit: "Kalendar Ekonomi",
			prev_week: "Minggu lalu",
			next_week: "Minggu depan",
			place_date: "Tarikh Carian",
			select: "Pilihan",
			select1: "Negara",
			select2: "Penting",
			start_time: "Waktu awal",
			end_time: "Waktu akhir",
			search: "Cari",
		},
		contract: {
			tit: "Perincian Kontrak",
			type1: "Seluruh",
			type2: "Pertukaran asing",
			type3: "Komoditas",
			type4: "Indeks",
			type5: "US Equity",
			type6: "Australian Equities",
			tit1: "Pertukaran asing utama",
			tit2: "Penukaran asing sekunder",
			tit3: "Komoditas",
			tit4: "Indeks utama",
			tit5: "Indeks sekunder",
			tit6: "US Equity",
			tit7: "Australian Equities",
			tit8: "Aturan umum",
			p1: "Nisbah rata yang kuat",
			p2: "Period kehendak dari perintah",
			p3: "Tidak terbatas",
			p4: "Waktu penyelesaian biaya overnight",
			p5: "Pengaturan sehari-hari; Musim panas: 05:00, Musim dingin: 06:00",
			p6: "Leverage, kadar overnight, dan informasi lainnya",
			p7: "Silakan merujuk ke platform perdagangan",
			p8: "Konten di atas berlaku untuk semua varietas perdagangan",
			tit9: "Kalender minggu perdagangan",
			p9: "Off Market",
			p10: "Jam perdagangan di atas tidak mempertimbangkan dampak liburan atau penyesuaian pasar khusus.。",
			p11: "Waktu transaksi khusus yang disarankan",
			p12: "Platform Login",
			p13: "，Periksa dalam perkenalan varietas relevan。",
		},
		forecast: {
			tit: "Perspektif perdagangan",
			p1: "Nilai rata-rata",
			p2: "Omong kosong.",
			p3: "Bearish",
			p4: "Plat horisontal",
			p5: "Kecenderungan",
		},
		news: {
			tit: "Berita waktu nyata",
		},
		platforms: {
			tit: "Platform perdagangan CXMDirects",
			p: "Interface sederhana dan intuitif, prestasi perdagangan yang bagus, untuk memenuhi kebutuhan lebih banyak investor. Mari kita mulai perdagangan di CXMDirects hari ini.",
			tit1: "Platform perdagangan CXMDirects yang nyaman dan mudah digunakan",
			tit2: "Trading dengan CXMDirects App kapan saja, di mana saja",
			li1: "Penjualan di ratusan pasar populer, pengawasan otoritas memastikan keamanan dana",
			li2: "Monitor trends pasar waktu nyata, melihat analisis transaksi, dan melayari berita keuangan terbaru",
			li3: "Tampilkan berbagai jenis grafik transaksi kapan saja, dengan fungsi menggambar aplikasi yang akses",
			li4: "Pengalaman platforma perdagangan pemenang penghargaan CXMDirects pada perangkat Android atau iOS Anda",
			code_download: "Pemindaian Kode Download",
			tit3: "Tidak perlu mengunduh, perdagangan online melalui perambar",
			li5: "Tidak diperlukan mengunduh, transaksi secara langsung online dalam perambar PC",
			li6: "Dihargai beberapa kali: Penyedar Pertukaran Luar Negeri terbaik di Asia pada tahun 2022, Platform Pertukaran Terbaik di Australia pada tahun 2022, dan Penyedar Teknologi Penyedar Pertukaran Luar Negeri Pertumbuhan Tercepat di dunia pada tahun 2022",
			li7: "Menyediakan indikator teknis yang kuat seperti MACD, KDJ, RSI, TRIX, DMA, CCI, dll",
			li8: "Dipersediakan dengan alat manajemen risiko seperti menghentikan kehilangan dan perlindungan keseimbangan negatif untuk mengurangi risiko potensial transaksi Anda",
			tit4: "Solusi sempurna untuk perdagangan desktop Anda",
			li9: "Tendensi pasar yang mudah dilihat dalam daftar yang dipilih",
			li10: "Aktifkan fungsi pengingat untuk menerima pengingat perubahan harga dan pemberitahuan informasi lainnya",
			li11: "Banyak layout grafik memungkinkan Anda melacak trends pasar dari dimensi waktu yang berbeda",
			li12: "Spesifikasi kontrak, sensasi transaksi, dan analisis transaksi semua berada di antarmuka yang sama, tanpa perlu untuk menukar",
			li13: "Dapatkan berita perdagangan terbaru langsung di CXMDirects",
			tit5: "Mengapa memilih platform perdagangan CXMDirects?",
			row5_p1: "Garansi keselamatan",
			row5_p2: "0 komisi",
			row5_p3: "Dukungan platform berbilang",
			row5_p4: "Graf teknis",
			row5_p5: "Akses Dimanapun",
			row5_p6: "Berbagai alat gambar",
		},
		quotes: {
			tit: "Quotasi waktu nyata",
			p1: "Kami memberikan Anda kutipan waktu nyata, sentimen pasar, dan berita berkaitan dengan produk keuangan populer saat ini.",
			hot: "Popular",
		},
		risk: {
			tit: "Manajemen resiko",
			p1: "Semua transaksi melibatkan risiko, dan alat manajemen risiko bebas yang disediakan oleh CXMDirects dapat fleksibel digunakan untuk mengendalikan risiko secara efektif tidak peduli kondisi pasar.",
			function_tit1: "Hentikan fungsi keuntungan/hentikan kehilangan",
			function_tit2: "Menjejak fungsi kehilangan hentian",
			function_p1: "Kunci keuntungan",
			function_p2: "Kehilangan terbatas",
			function_p3: "Maksimalkan terkunci dalam keuntungan",
			function_p4: "Tidak perlu memantau posisi secara otomatis",
			function_p5: "Menyesuaikan harga penutup kehilangan",
			row2_tit1: "Hentikan fungsi keuntungan/hentikan kehilangan",
			row2_p1: "Saat menentukan perintah baru atau mengubah perintah yang ada, kita bisa memilih untuk menentukan 'menghentikan keuntungan' dan 'menghentikan kehilangan'. Setelah menetapkan, perintah secara umum akan dilikwidasi berdasarkan harga sasaran yang Anda tetapkan, membantu Anda mengunci keuntungan ketika mencapai sasaran atau mengurangi kerugian ketika trend pasar tidak favorable. Silakan perhatikan bahwa setiap perintah mungkin mengalami ruang karena fluktuasi pasar, dan sistem tidak akan dapat mengeksekusi perintah pada harga standar, tetapi akan menutup posisi untuk Anda pada harga yang paling favorit dekat dengan harga sasaran.",
			row2_tit2: "Contoh",
			row2_p2: "Harga saat ini EUR/USD adalah 1,13816/1,13837 (menjual/membeli). Anda telah menetapkan satu tangan (satu tangan=100000 euro) membeli perintah pada 1,13837, dan menetapkan perintah berhenti kehilangan pada 1,13806.",
			row2_p3: "Secara umum, ketika harganya menurun ke 1,13806, perintah menghentikan kehilangan Anda akan dipicu dan posisi akan ditutup dengan harga 1,13806, yang menyebabkan kehilangan total $ 31.",
			row2_p4: "Namun, ketika lingkungan pasar mengalami perubahan tiba-tiba dan harganya langsung menurun dari 1,13837 ke 1,13795, melewati target kehilangan hentian Anda, pasar akan mengalami celah dan sistem tidak akan dapat menutup posisi pada 1,13806. Namun harga yang paling baik berikutnya, 1,13795, akan digunakan untuk menutup posisi untuk Anda, yang berasal dari kehilangan akhir $ 42.",
			row3_tit1: "Menjejak fungsi kehilangan hentian",
			row3_tit2: "Contoh",
			row3_p1: "Fungsi menghentikan kehilangan pelacakan (juga dikenal sebagai kehilangan berhenti mobil) kuat, dan harga menghentikan kehilangan dapat secara otomatis berubah dengan keuntungan, memungkinkan Anda untuk maksimalkan keuntungan terkunci atau mengurangi kehilangan tanpa terus mengawasi posisi Anda. Ketika membuat perintah baru, Anda hanya perlu menetapkan titik kehilangan hentian pelacakan. Ketika harga bergerak dalam arah yang baik untuk Anda, perintah kehilangan hentian Anda akan secara otomatis diperbaiki berdasarkan harga terbaru. Sebaliknya, ketika harga berubah dalam arah yang tidak favorit bagi Anda, perintah menghentikan kehilangan akan dimulai dan posisi akan ditutup dalam jarak dari titik kehilangan yang Anda tetapkan. Silakan perhatikan bahwa setiap perintah mungkin mengalami ruang karena fluktuasi pasar, dan sistem tidak akan dapat mengeksekusi perintah pada harga standar Anda, tetapi akan menutup posisi untuk Anda pada harga paling favorit dekat dengan harga sasaran.",
			row3_p2: "Harga saat ini untuk EUR/USD adalah 1,13816/1,13837 (menjual/membeli). Anda menetapkan satu tangan membeli perintah pada 1.13837 dan menetapkan kehilangan pelacakan berhenti 100 poin (0.00100).",
			row3_p3: "Ketika harga produk pada 1,13816, harga kehilangan berhenti Anda adalah 1,13716. Jika harga penjualan produk meningkat ke 1,13845, harga kehilangan berhenti akan diperbarui berdasarkan jarak yang Anda tetapkan, dan harga kehilangan berhenti diperbarui adalah 1,13745.",
			row3_p4: "Sebaliknya, ketika harga produk turun dari 1.13845 ke 1.13745, kehilangan pelacakan akan dipicu dan posisi penutup akan dieksekusi pada 1.13745.",
			tip: "Titik: Biasanya, unit minimal perubahan dalam harga instrumen keuangan disebut titik. Di platform CXMDirects, itu merujuk ke digit terakhir atau desimal dari harga alat.",
		},
		sentiment: {
			tit: "Indeks Emosi",
			type1: "Seluruh",
			type2: "Pertukaran asing",
			type3: "Komoditas",
			type4: "Indeks",
			long: "Panjang",
			short: "Posisi Bearish",
		},
	},

	layout: {
		aside: {
			nav1: "Transaksi",
			nav2: "Pasar",
			nav3: "Info real-time",
			nav4: "Sekolah",
			nav5: "Aku",
			newsDialog: {
				title: "Pusat Pesan",
				type1: "Notifikasi sistem",
				type2: "Pengumuman",
				allRead: "Tanda semua sebagai baca",
			},
			settingDialog: {
				title: "Mengatur",
				nav1: "Rutin",
				nav2: "Tampilkan keutamaan",
				nav3: "Informasi sistem",
				logout: "Daftar keluar dari daftar masuk",
				setting1: "Bahasa",
				setting2: "Transaksi",
				setting2_tip: "Tambahan otomatis varietas gudang terbuka untuk pemilihan diri",
				setting3: "Feedback",
				setting3_tip: "Rekomendasi fungsional",
				setting4: "Autentikasi nama asli",
				setting4_tip: "Autentikasi nama asli",
				setting5: "Mod tema",
				setting5_label1: "Gelap",
				setting5_label2: "Warna cerah",
				setting6: "Warna naik dan turun",
				setting6_label1: "Green rise and red fall",
				setting6_label2: "Red rise and green fall",
				setting7: "Pengaturan Chart",
				setting7_label1: "Editi Standard",
				setting7_label2: "Editi Tampilan Penjualan",
				setting8: "Deklarasi dan Perjanjian",
				setting8_label1: "Polisi Privasi",
				setting8_label2: "Penjelasan Produk",
				setting8_label3: "Penjelasan Risiko",
				setting8_label4: "Perjanjian Pelanggan",
				feedback_title: "Rekomendasi fungsional",
			}
		},
		footer: {
			hot: "Variasi Popular",
			sort: "Variasi",
			code: "Kode",
			buy_price: "Harga pembelian",
			sell_price: "Harga penjualan",
			chg: "Jarak fluktuasi",
		},
		header: {
			place_search: "Cari variasi transaksi",
			balance: "Kesimbangan yang tersedia",
			balance_tip: "Saldo tersedia: Jumlah dalam akun yang dapat digunakan untuk membuka posisi baru",
			profit_loss: "Profit dan kehilangan",
			asset_view: "Overview Asset",
			netValue: "Nilai neto",
			netValue_tip: "Nilai neto: Nilai akun saat ini, termasuk keuntungan dan kehilangan semua posisi yang ditahan",
			marginLevel: "Tingkat Margin",
			margin: "Margin",
			margin_tip: "Margin: Jumlah yang diperlukan untuk membuka dan mempertahankan semua posisi",
			maintainsMargin: "Jaga margin",
			maintainsMargin_tip: "Pertahankan Margin: jumlah margin minimal yang Anda butuhkan untuk mempertahankan dalam akun Anda ketika memegang semua posisi.",

		},
	},
	table: {
		label_name: "Nama",
		label_buyPrice: "Harga membeli",
		label_sellPrice: "Harga penjualan",
		label_variableValue: "Nilai variabel",
		trade: "Transaksi",
		label_code: "Kode produk",
		label_title: "Nama",
		label_start: "Penting",
		label_country: "Negara",
		label_event: "Peristiwa",
		label_previous: "Nilai sebelumnya",
		label_predictive: "Perkiraan",
		label_announced: "Nilai yang diumumkan",
		label_stop_loss_min: "Jarak minimum dari urutan kehilangan hentian",
		label_difference: "Perbedaan harga dinamik",
		label_contract_size: "Ukuran kontrak",
		label_max_hand: "Nomor maksimum tangan transaksi tunggal untuk ukuran kontrak",
		label_min_hand: "Jumlah transaksi minimum per transaksi",
		label_trade_week: "Kalender minggu perdagangan",
		label_week: "1 minggu",
		label_month: "1 bulan",
		label_quarter: "1 seperempat",
		label_dailyTrend: "Trend sehari",
		label_tradeId: "ID Transaksi",
		label_breed: "Variasi",
		label_tradeType: "Jenis transaksi",
		label_currency: "Valuta",
		label_amount: "Uang",
		label_balance: "Balance",
		label_type: "Tipe",
		label_time: "Waktu",
		label_orderNumber: "Nomor perintah",
		label_pointer_number: "Kiraan tangan",
		label_price_money: "Harga pembukaan",
		label_stop_win_price: "Hentikan surplus",
		label_stop_lose_price: "Hentikan kehilangan",
		label_openTime: "Waktu Buka",
		label_profit_loss: "Profit dan kehilangan",
		label_sell_buy: "Menjual/Pembelian Harga",
		label_chg: "Jarak fluktuasi",
		label_sell_price: "Harga penjualan",
		label_buy_price: "Harga pembelian",
		label_condition: "kondisi",
	},
	form: {
		label_feedback: "Pertanyaan dan saran",
		place_feedback: "Silakan masukkan pertanyaan atau saran Anda",
		label_img: "Gambar",
		label_img_tip: "Opsional, menyediakan foto layar dari masalah",
		feedback_tip: "Jika Anda memiliki pertanyaan mendesak, silakan hubungi",
		online: "Layanan Online",
		label_name: "Nama",
		place_name: "Silakan masukkan nama Anda",
		label_phone: "Telepon",
		place_phone: "Silakan masukkan nomor telepon",
		label_email: "E-mail",
		place_email: "Alamat surel",
		message_email: "Silakan masukkan alamat email Anda",
		label_nationality: "Nasional",
		place_nationality: "Silakan masukkan nasional Anda",
		place_questionType: "Jenis Masalah",
		message_questionType: "Silakan pilih tipe",
		questionType0: "Platform perdagangan",
		questionType1: "Produk dan biaya",
		questionType2: "Daftar pada analisis pasar sehari-hari",
		questionType3: "Layanan pelanggan",
		questionType4: "Lain",
		place_question: "Pertanyaanmu",
		message_question: "Silakan masukkan pertanyaan",
		submit_question: "Kirim Masalah",
		message_phone: "Silakan masukkan nomor telepon Anda",
		label_password: "Sandi",
		place_password: "Silakan masukkan sandi",
		message_password: "Silakan masukkan sandi",
		label_confirmPassword: "Konfirmasi Sandi",
		place_confirmPassword: "Silakan masukkan sandi lagi",
		message_confirmPassword: "Silakan masukkan sandi lagi",
		label_captcha: "Kode verifikasi",
		place_captcha: "Silakan masukkan kode verifikasi",
		message_captcha: "Silakan masukkan kode verifikasi",
		get_captcha: "Dapatkan kode verifikasi",
		label_inviteId: "Kode undangan",
		place_inviteId: "Kode undangan (pilihan)",
		to: "ke",
		start_time: "Waktu awal",
		end_time: "Waktu akhir",
		label_cardNumber: "Nomor Identifikasi",
		place_cardNumber: "Silakan masukkan nomor ID Anda",
		message_cardNumber: "Silakan masukkan nomor ID Anda",
		label_cardMain: "ID depan",
		message_cardMain: "Silakan masukkan ID depan",
		label_cardBack: "Di sisi belakang dokumen",
		message_cardBack: "Silakan masukkan sisi terbalik ID",
		confirm_modify: "Konfirmasi modifikasi",
		label_realName: "Nama penuh",
		place_realName: "Silakan masukkan nama depanmu",
		message_realName: "Silakan masukkan nama penuh Anda",
		label_firstName: "Nama keluarga",
		place_firstName: "Silakan masukkan nama belakangmu",
		message_firstName: "Silakan masukkan nama belakang",
		label_lastName: "Nama",
		place_lastName: "Silakan masukkan nama tengah Anda",
		message_lastName: "Silakan masukkan nama",
		label_birthday: "Tanggal lahir",
		place_birthday: "Silakan masukkan tanggal kelahiranmu",
		message_birthday: "Silakan masukkan tanggal lahir",
		place_nowPsd: "Silakan masukkan sandi saat ini",
		message_nowPsd: "Silakan masukkan sandi saat ini",
		place_newPsd: "Silakan masukkan sandi baru",
		message_newPsd: "Silakan masukkan sandi baru",
		place_crmPsd: "Silakan konfirmasi kata sandi baru",
		message_crmPsd: "Silakan konfirmasi kata sandi baru",
		label_breed: "Variasi",
		place_breed: "Silakan masukkan varietas",
		label_phase: "Ketika",
		label_buyPrice: "Harga pembelian",
		label_sellPrice: "Harga penjualan",
		label_do: "Oleh",
		label_height: "Di atas",
		label_low: "Di bawah",
		label_equal: "Atau sama dengan",
		labelWalletName: "Nama Dompet",
		messageWalletName: "Silakan masukkan Nama Dompet",
		placeWalletName: "Silakan masukkan Nama Dompet",
		labelWalletAddress: "Alamat Dompet",
		messageWalletAddress: "Silakan masukkan Alamat Dompet",
		placeWalletAddress: "Silakan masukkan Alamat Dompet",
		labelAmount: "Jumlah",
		messageAmount: "Silakan masukkan jumlah",
		placeAmount: "Silakan masukkan jumlah",
		placeTimeLimit: "Silakan masukkan batas waktu",
		messageTimeLimit: "Silakan masukkan batas waktu",
	},
	pay: {
		title: "Deposit dan penarikan yang nyaman",
		p1: "Banyak saluran pembayaran yang biasanya digunakan, mendukung deposit dan penarikan cepat",
		p2: "Beberapa metode pembayaran mungkin tidak tersedia di negara/wilayah Anda",
		p3: "Selamat datang untuk mengalami layanan CXMDirects",
		btn: "Segera membuka akun untuk transaksi",
	},
	header: {
		tip1: "Perdagangan tinggi adalah produk keuangan kompleks dengan risiko tinggi kehilangan cepat.",
		btn1: "Platform perdagangan",
		btn2: "Daftar keluar dari daftar masuk",
		btn3: "Login",
		BigUint64Array: "Transaksi segera",
	},
	footer: {
		help: "Hubungi Kami",
		tip1: "Ikuti komunitas kita",
		tip2: "Silakan perhatikan bahwa CXMDirects tidak mendirikan kelompok Telegram resmi, dan kelompok Telegram apapun yang terbentuk di bawah nama CXMDirects tersangka penipuan.",
		tip3: "Peringatan Risiko: Penjualan mungkin menyebabkan Anda kehilangan semua dana Anda. perdagangan derivat OTC tidak cocok untuk semua orang. Silakan baca dengan hati-hati dokumen hukum kami sebelum menggunakan layanan kami dan pastikan Anda sepenuhnya memahami risiko terlibat sebelum perdagangan. Anda tidak benar-benar memiliki atau memegang aset dasar yang terkait.",
		tip4: "CXMDirects tidak memberikan nasihat, rekomendasi, atau pendapat mengenai membeli, memegang atau menjual. Semua produk yang kita persediakan adalah derivat-over-counter berdasarkan aset global. Semua layanan yang disediakan oleh CXMDirects hanya berdasarkan eksekusi instruksi transaksi.",
		tip5: "CXMDirects adalah marka bisnis yang biasanya digunakan oleh banyak perusahaan dan beroperasi melalui perusahaan berikut:",
		tip6: "CXMDirects International Ltd adalah penyebab produk keuangan yang diterangkan atau tersedia untuk digunakan di situs ini. CXMDirects International Ltd diijinkan dan diatur oleh Mauritius Financial Services Commission (FSC) dengan nomor lisensi GB20025791 dan alamat terdaftar di 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius",
		tip7: "CXMDirects Global Pty Ltd memiliki nomor pendaftaran ABN 90 149 011 361 dan nomor License Layanan Finanzial Australian (AFSL) 398528.",
		tip8: "CXMDirects Holding Ltd diberi otorisasi dan diatur oleh Kepulauan Monetar Authority (CIMA) dengan nomor SIB 1612446.",
		tip9: "Informasi di situs ini tidak ditujukan untuk penduduk Amerika Serikat, Kanada, Jepang atau Selandia Baru; Informasi di situs ini tidak sesuai bagi siapapun di negara atau yurisdiksi apapun yang melanggar hukum atau peraturan lokal karena penerbitannya atau penggunaannya. Silakan perhatikan bahasa Inggris adalah bahasa utama yang digunakan dalam layanan kami dan juga bahasa yang secara hukum mengikat dalam semua dokumen kontrak kami. Terjemahan bahasa lain hanya untuk referensi. Dalam kasus ambigusi antara terjemahan teks dan bahasa Inggris, versi Inggris akan menang.",
		tip10: "SSL aman komunikasi enkripsi. ©  CXMDirects All rights reserved.",
		link1: "Polisi Privasi",
		link2: "Penjelasan Produk",
		link3: "Proses keluhan",
		link4: "Penjelasan Risiko",
		link5: "Perjanjian Pelanggan",
		toTop: "Atas",
	},
	nav: {
		tit1: "Produk",
		tit2: "Transaksi",
		tit3: "Belajar investasi",
		tit4: "Tentang kita",
		nav1: "Pertukaran asing",
		nav2: "Indeks",
		nav3: "Komoditas",
		nav4: "Saham",
		nav5: "Platform perdagangan",
		nav6: "Strategi perdagangan",
		nav7: "Perspektif perdagangan",
		nav8: "Kalendar Ekonomi",
		nav9: "Berita waktu nyata",
		nav10: "Quotasi waktu nyata",
		nav11: "Indeks Emosi",
		nav12: "Manajemen resiko",
		nav13: "Perincian Kontrak",
		nav14: "Perkenalan ke Investasi",
		nav15: "Insight investasi",
		nav16: "Academy",
		nav17: "Tentang CXMDirects",
		nav18: "Hadiah dan kehormatan",
		nav19: "Pusat Media",
		nav20: "Keamanan keuangan",
		nav21: "Hadiah dan hadiah",
		nav22: "Affiliates",
		nav23: "Hubungi Kami",
		nav24: "Pertanyaan sering Ditanyakan",
		nav25: "Pusat Bantuan",
	},

	city: {
		albania: "Albania",
		algeria: "Algeria",
		argentina: "Argentina",
		armenia: "Armenia",
		australia: "Australia",
		pakistan: "Pakistan",
		austria: "Austria",
		bahrain: "Bahrain",
		belgium: "Belgia",
		bosnia_and_Herzegovina: "Bosnia dan Herzegovina",
		brazil: "Brazil",
		brunei: "Brunei",
		bulgaria: "Bulgaria",
		cambodia: "Kambodja",
		canada: "Kanada",
		cameroon: "Kamerun",
		chile: "Chile",
		colombia: "Kolombia",
		costa_Rica: "Costa Rica",
		croatia: "KroatiaName",
		cyprus: "Cyprus",
		czech_Republic: "Republik Ceko",
		denmark: "Denmark",
		dominican_Republic: "Republik DominikanName",
		egypt: "Mesir",
		estonia: "Estonia",
		ethiopia: "Ethiopia",
		finland: "Finland",
		france: "Perancis",
		georgia: "Georgia",
		germany: "Jerman",
		ghana: "Ghana",
		greece: "Yunani",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Cina",
		hungary: "Hungary",
		iceland: "Islandia",
		ireland: "Irlandia",
		italy: "Italia",
		india: "India",
		indonesia: "Indonesia",
		israel: "Israel",
		iran: "Iran",
		iraq: "Irak",
		japan: "Jepang",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Korea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirgistan",
		laos: "Laos",
		latvia: "Latvia",
		lithuania: "Lithuania",
		luxembourg: "Luxemburg",
		macao_China: "Macau, Cina",
		macedonia: "Macedonia",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Meksiko",
		moldova: "Moldova",
		monaco: "Monaco",
		mongolia: "Mongolia",
		montenegro: "Montenegro",
		morocco: "Maroko",
		myanmar: "Myanmar",
		netherlands: "Belanda",
		new_Zealand: "Seland Baru",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norwegia",
		oman: "Oman",
		palestine: "Palestina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "Filipina",
		poland: "poland",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Qatar",
		romania: "Rumania",
		russia: "Rusia",
		republic_of_Trinidad_and_Tobago: "Trinidad dan Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Arab Saudi",
		serbia: "Serbia",
		singapore: "Singapura",
		slovakia: "Slovakia",
		slovenia: "Slovenia",
		south_Africa: "Afrika Selatan",
		spain: "Spanyol",
		sri_Lanka: "Sri Lanka",
		sweden: "Swedia",
		switzerland: "Swiss",
		taiwan_China: "Taiwan, Cina",
		tajikistan: "Tajikistan",
		tanzania: "Tanzania",
		thailand: "Thailand",
		turkey: "T ürkiye",
		turkmenistan: "Turkmenistan",
		ukraine: "Ukraina",
		united_Arab_Emirates: "UAE",
		united_Kingdom: "Inggris",
		united_States: "Amerika",
		uzbekistan: "Uzbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaijan",
		bangladesh: "Bangladesh",
		belarus: "Belarus",
		belize: "Belize",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "pulau perawan Inggris",
		burkina_Faso: "burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cape Verde",
		cayman_Islands: "Kepulauan Cayman",
		central_African_Republic: "Republik Afrika Tengah",
		chad: "Chad",
		comoros: "Komor",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Kongo (Kinshasa)",
		djibouti: "Djibouti",
		ecuador: "	Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guinea Ekvatorial",
		eritrea: "Eritrea",
		fiji: "Fiji",
		gabon: "Gabon",
		gambia: "Gambia",
		greenland: "Greenland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Pulau Man",
		cote_d_Ivoire: "Pantai Ivory",
		jamaica: "Jamaica",
		jordan: "Jordan",
		lebanon: "Lebanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libya",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Mauritius",
		mozambique: "Mozambique",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Korea Utara",
		reunion: "Reunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalia",
		sudan: "Sudan",
		suriname: "Surinam",
		eswatini: "Eswatini",
		syria: "Syria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisia",
		united_States_Virgin_Islands: "Kepulauan Perawan, Amerika Serikat",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikan",
		yemen: "Yemen",
		yugoslavia: "Yugoslavia",
		zambia: "Zambia",
		zimbabwe: "zimbabwe",
		china: "Tiongkok",
	}
};
