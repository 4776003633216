<template>
  <div class="app">
    <Header ref="headers" />
    <section class="main-container">
      <Aside />
      <section class="container">
        <div class="main">
          <router-view></router-view>
        </div>
        <div class="footer">
          <Footer />
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import Aside from "./components/aside";
import Footer from "./components/footer";
import Header from "./components/header";
export default {
  name: "Layout",
  components: {
    Header,
    Aside,
    Footer,
  },

  data() {
    return {};
  },

  created() {},

  mounted() {},

  methods: {},
};
</script>
<style lang="less" scoped>
.app {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  .main-container {
    flex: 1;
    display: flex;

    .container {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .main {
        flex: 1;
        display: flex;
        width: 100%;

        // .trade-wrap {
        //     display: flex;
        //     height: 100%;

        //     .left_drag-l,
        //     .left_drag-r {
        //         border-left: 4px solid var(--background-w1);
        //         cursor: ew-resize;
        //     }
        // }
      }

      .footer {
        // height: var(--el-footer-height);
      }
    }
  }
}
</style>
