export default {
	common: {
		learn: {
			title1: "기본 잔액",
			content1: "CXMDirects 현금 및 호스팅된 암호화폐 잔액 서비스를 무료로 제공하여 현금과 지원되는 암호화폐를 무료로 보관할 수 있습니다. 다음 중 하나에서 암호화폐를 전송할 수 있습니다. CXMDirects 사용자의 기본 잔액을 다른 사용자의 기본 잔액으로 수수료 없이 이체할 수 있습니다.\n" +
				"\n" +
				"네트워크 거래 수수료\n" +
				"\n" +
				"암호화폐 네트워크에서의 거래(예: 암호화폐를 외부로 전송하는 경우 CXMDirects 플랫폼), CXMDirects 네트워크 거래 수수료(예: 채굴자 수수료)를 발생시키고 지불합니다. 암호화폐를 보낼 때 CXMDirects 지갑에서 다른 지갑으로 송금하는 경우, 독립형 지갑 간 송금에 대한 일반적인 네트워크 수수료 추정치를 기준으로 수수료를 부과합니다. 그러나 최종 수수료는 CXMDirects 결제 수수료는 거래 일괄 처리 또는 네트워크 혼잡도 변화 등의 요인으로 인해 예상 수수료와 다를 수 있습니다. 모든 수수료는 거래 시점에 공개됩니다..\n" +
				"\n" +
				"효율성 향상 및 네트워크 수수료\n" +
				"\n" +
				"거래 일괄 처리로 인한 잠재적인 효율성 향상으로 인해, 특정 일괄 처리 내에서 사용자가 지불하는 예상 네트워크 수수료의 총액이 다음과 같이 지불하는 최종 네트워크 수수료를 초과할 수 있습니다 CXMDirects.\n" +
				"\n" +
				"라이트닝 네트워크 처리 수수료\n" +
				"\n" +
				"라이트닝 네트워크는 비트코인 블록체인과 관련된 거래 수수료 없이 비트코인을 전송할 수 있는 소액 결제 네트워크입니다. 다음에서 비트코인을 송금할 때 CXMDirects 라이트닝 네트워크를 통해 전송된 비트코인 금액의 0.1%에 해당하는 처리 수수료를 부과합니다.\n" +
				"\n" +
				"현금 추가 및 현금 인출\n" +
				"\n" +
				"현금 또는 현금 인출을 추가할 때 선택한 결제 방법에 따라 수수료가 부과될 수 있습니다. 거래를 완료하기 전에 수수료 세부 정보를 검토하여 관련 수수료를 파악하는 것이 중요합니다.\n" +
				"\n" +
				"신용 거래\n" +
				"\n" +
				"다음에서 USD를 빌리는 경우 CXMDirects 의 계열사 또는 CXMDirects (해당 대출 계약에 따라 승인된 대로) BTC 담보를 매각해야 합니다., 총 거래액의 2%의 정액 수수료가 부과됩니다.",
			title2: "거래 수수료 및 스프레드",
			content2: "CXMDirects 수수료\n" +
				"\n" +
				"다음에서 암호화폐를 구매, 판매 또는 환전할 때 CXMDirects, 수수료가 부과됩니다. 이러한 수수료는 주문 시점에 계산되며 선택한 결제 방법, 주문 규모, 시장 상황, 관할 지역 및 거래를 원활하게 하기 위해 발생하는 기타 비용 등의 요인에 따라 영향을 받을 수 있습니다.\n" +
				"\n" +
				"거래를 제출하기 전에 거래 미리보기 화면에서 수수료를 확인할 수 있습니다. 유사한 거래의 경우 수수료가 다를 수 있다는 점에 유의하시기 바랍니다. 주문을 완료합니다, 귀하는 해당 비용을 지불하는 데 동의합니다. CXMDirects 해당 거래에 대한 수수료.\n" +
				"\n" +
				"확산\n" +
				"\n" +
				"간단한 매수 및 매도 주문을 할 때 CXMDirects 호가에는 스프레드가 포함됩니다. 스프레드는 한 암호화폐에서 다른 암호화폐로 전환할 때도 환율에 포함됩니다.\n" +
				"\n" +
				"매수, 매도 또는 전환 주문을 미리 보기 전에 스프레드를 확인하려면 미리 보기 화면에서 스프레드를 확인하세요:\n" +
				"\n" +
				"매수 및 매도 주문: 자산의 가격 항목 옆에 있는 도구 설명을 탭하면 스프레드를 볼 수 있습니다.\n" +
				"\n" +
				"주문 전환: 견적 환율 옆의 툴팁을 탭합니다.\n" +
				"\n" +
				"스프레드는 거래 성공 가능성을 높이고 다음을 허용합니다. CXMDirects 을 클릭하면 주문을 처리하는 동안 견적 가격을 일시적으로 고정할 수 있습니다. CXMDirects 는 거래에서 발생한 초과 스프레드를 보유할 수 있습니다. 유사한 거래의 경우 스프레드가 다를 수 있다는 점에 유의하세요.\n" +
				"\n" +
				"CXMDirects 고급\n" +
				"\n" +
				"다음에 대한 스프레드는 포함되어 있지 않습니다. CXMDirects 주문서와 직접 상호 작용하기 때문에 고급입니다.\n" +
				"\n" +
				"CXMDirects 하나\n" +
				"\n" +
				"CXMDirects 하나는 수수료 없이 암호화폐를 매매할 수 있는 구독 상품으로, 특정 제한이 있습니다. 회원은 여전히 호가에 스프레드가 포함될 수 있습니다.\n" +
				"\n" +
				"거래 수수료 및 스프레드 변경\n" +
				"\n" +
				"CXMDirects 수수료 및 스프레드 변경을 가끔 테스트합니다. 이러한 변경 사항은 다양한 지역, 자산, 주문 규모, 거래 유형에 걸쳐 적용될 수 있습니다.\n" +
				"\n" +
				"CXMDirects 는 거래를 미리 볼 때마다 그리고 거래 내역에서 스프레드와 수수료를 포함한 총 금액을 표시합니다. \n" +
				"\n" +
				"거래 체결\n" +
				"\n" +
				"기본 잔고에서 모든 거래는 다음을 통해 실행됩니다. CXMDirects 거래소의 중앙 지정가 주문창 또는 경쟁 경매. 시장의 호가창, 매수, 매도, 심도를 실시간으로 볼 수 있습니다. 이러한 호가창은 거래 중에 표시되는 호가를 결정합니다.\n" +
				"\n" +
				"CXMDirects 는 마켓 메이커와 주문 흐름에 대한 지불(PFOF) 관계가 없습니다.",
			title3: "CXMDirects 카드",
			content3: "지출은 CXMDirects 카드에는 거래 수수료가 없습니다. \n" +
				"\n" +
				"CXMDirects 에는 암호화폐를 사고 파는 가격에 스프레드가 포함되어 있습니다. 이를 통해 거래를 제출하기 전에 거래 내역을 검토하는 동안 거래 체결 가격을 일시적으로 고정할 수 있습니다.  \n" +
				"\n" +
				"ATM 운영자가 수수료를 부과할 수 있습니다. 자세한 내용은 CXMDirects 자세한 내용은 카드 소유자 계약서를 참조하세요.",
			title4: "스테이킹 서비스",
			content4: "스테이킹 또는 언스테이킹 수수료는 없습니다. CXMDirects 는 네트워크에서 받는 보상에 따라 수수료를 받습니다. 표준 수수료는 ADA, ATOM, DOT, MATIC, SOL 및 XTZ의 경우 35%입니다. ADA, ATOM, DOT, SOL 및 XTZ의 경우, 적격 자격을 갖춘 경우 수수료는 26.3%입니다. CXMDirects 한 회원. ETH 수수료율은 자산 세부정보에서 확인할 수 있습니다. 일부 사용자는 더 낮은 수수료율의 스테이킹 프로모션 제안을 받을 수 있습니다. 계정에 표시되는 APY와 보상은 항상 커미션 후 받는 수익을 반영합니다. 수수료에 대한 자세한 내용은  CXMDirects 사용자 동의.",
			title5: "자산 복구",
			content5: "지원되지 않는 암호화폐를 보낸 경우 CXMDirects 계정이 복구 대상일 수 있습니다. 복구 시도에 대해서는 네트워크 수수료가 부과됩니다. 추정 가치가 100달러를 초과하는 복구의 경우 100달러 초과 금액에 대해 5%의 복구 수수료도 부과됩니다. 복구 예상 가치는 실제 시장 가치와 다를 수 있습니다..",
		},
		award1: "2023년 아시아 최고 스프레드 계약 브로커",
		award2: "2022 세계 최고의 브로커",
		award3: "2022년 가장 신뢰할 수 있는 브로커",
		award4: "2021년도 가장 신뢰할 수 있는 브랜드",
		award5: "최고의 핀테크 브로커",
		award6: "거래 투명성 브로커",
		country: "분석",
		health: "건강",
		analysis: "국가",
		information: "정보",
		help: "도움말",
		confirm: "확인",
		cancel: "취소",
		tip: "힌트",
		place_select: "선택하십시오.",
		placeEnter: "입력하십시오.",
		countryCode: "국가 코드",
		submit: "제출",
		more: "추가",
		deposit: "입금",
		real_trade: "실제 거래",
		paper_trade: "모의 거래",
		build_paperTrade: "아날로그 계정 만들기",
		start_trade: "지금 거래 시작",
		app_name: "CXMDirects",
		success: "성공！",
		dataSources_name: "Trading Central",
		reset: "재설정",
		iknow: "알았어",
		noData: "데이터 없음",
		modify: "수정",
		pingcang: "창고 정리",
		all: "모두",
		tip_cardMain: "신분증 정면 사진을 찍거나 올리다",
		tip_cardBack: "신분증 뒷면의 사진을 찍거나 올리다",
		tip_cardMain1: "운전면허 정면 사진을 찍거나 올리다",
		tip_cardBack1: "운전면허 뒤에 있는 사진 찍거나 올리기",
		tip_cardMain2: "여권 사진 촬영 또는 업로드",
		tip_cardBack2: "여권 사진 촬영 또는 업로드",
	},
	currency: {
		btcusdt: "비트코인(가상 화폐)",
		ethusdt: "이더랜드",
		ltcusdt: "라이트코인(가상 화폐)",
		xrpusdt: "리플(통화)",
		adausdt: "통화(분수 통화로 사용)",
		eosusdt: "포멜로 코인",
		dotusdt: "보카 레이튼(구 보카 레이튼), 러시아 통화",
		trxusdt: "웨이브 농지 코인 (컴퓨팅)",
		xmrusdt: "먼로 동전(예: 미국 달러)",
		dogeusdt: "강아지 코인",
		bnbusdt: "BNB",
		solusdt: "SOL",
		aptusdt: "Aptusdt",
		fx_saudusd: "호주 달러 미국 달러",
		fx_sgbpusd: "파운드-달러",
		fx_seurusd: "유로달러",
		fx_snzdusd: "뉴질랜드 달러(NZD) 미국 달러",
		fx_susdcad: "미국 달러-캐나다 달러",
		fx_susdchf: "미국 달러 스위스 프랑",
		fx_susdjpy: "미국 달러-엔",
		fx_saudcad: "호주 달러 캐나다 달러",
		fx_saudchf: "호주 달러 및 스위스 프랑",
		fx_saudjpy: "호주 달러 일본 엔",
		fx_saudnzd: "호주 달러 뉴질랜드 달러(NZD)",
		fx_scadchf: "캐나다 달러 스위스 프랑",
		fx_scadjpy: "캐나다 달러 일본 엔",
		fx_schfjpy: "스위스 프랑 엔",
		fx_seuraud: "유로 호주 달러",
		fx_seurcad: "유로 캐나다 달러",
		fx_seurgbp: "유로 파운드 스털링",
		fx_seurjpy: "유로 엔",
		fx_seurnzd: "유로 뉴질랜드 달러(NZD)",
		fx_sgbpaud: "영국 파운드 호주 달러",
		fx_sgbpchf: "파운드 스털링 스위스 프랑",
		fx_sgbpjpy: "GBPJPY",
		fx_sgbpnzd: "파운드 뉴질랜드 달러(NZD)",
		fx_scadnzd: "캐나다 달러 뉴질랜드 달러",
		hf_CL: "WTI 뉴욕 원유",
		hf_OIL: "브렌트유",
		hf_GC: "뉴욕 골드",
		hf_SI: "뉴욕 실버",
		hf_HG: "구리(외래어)",
		hf_NG: "미국 천연 가스",
		hf_CAD: "구리(외래어)",
		hf_AHD: "런던 알루미늄",
		HX_AAPL: "석류",
		HX_BABA: "아라비안나이트의 캐릭터 알리 바바",
		HX_GOOG: "구글",
		HX_WMT: "월마트, 월마트(소매업체)",
		HX_AMZN: "HX_AMZN",
		HX_TSLA: "니콜라 테슬라(1856-1943), 세르비아의 발명가이자 엔지니어",
		HX_N225: "닛케이 225",
		HX_GDAXI: "DAX30, 독일",
		HX_IBEX: "IBEX35, 스페인",
		HX_SPX: "S&P 500 지수",
		HX_FTSE: "FTSE 100",
		HX_KS11: "코스피 한국",
		HX_NZ50: "뉴질랜드 50",
		HX_PSI20: "포르투갈 PSI20",
		hf_ZSD: "런던 아연",
		hf_NID: "런던 니켈",
		hf_PBD: "런던 리드",
		hf_SND: "런던 주석",
		hf_XAU: "런던 골드(현물 골드)",
		hf_XAG: "런던 실버(현물 실버)",
		hf_XPT: "플래티넘 선물",
		hf_XPD: "팔라듐 선물",
		hf_CT: "미국 코튼",
		hf_SM: "대두 분말",
		hf_BO: "미국산 대두유",
		HX_SENSEX: "센섹스, 뭄바이, 인도",
		HX_KLSE: "FTSE 말레이시아 KLCI",
		HX_KSE100: "카라치, 파키스탄",
		HX_FCHI: "CAC40, 프랑스",
		HX_SX5E: "유럽 스누커 50",
		HX_ICEXI: "ICEX, 아이슬란드",
		HX_ASE: "ASE 아테네, 그리스",
		HX_OMXC20: "OMX 코펜하겐 20",
		HX_OSEBX: "OSEBX, 노르웨이",
		HX_OMXSPI: "옴스파이, 스웨덴",
		HX_AMD: "차오웨이 반도체",
		HX_DIS: "디즈니(이름)",
		HX_MELI: "MercadoLibre Inc",
		HX_PSX: "Phillips 66",
		HX_TRUE: "TrueCar Inc",
		HX_SDRL: "Seadrill Ltd",
		HX_MA: "마스터카드(브랜드)",
		HX_NVDA: "NVIDIA, 컴퓨터 그래픽 카드 회사",
		HX_INTC: "인텔",
		HX_GOGO: "Gogo Inc",
		HX_STX: "씨게이트 기술",
		usdcusdt: "USDC",
		filusdt: "FIL",
		maticusdt: "MATIC",
		linkusdt: "LINK",
		avaxusdt: "AVAX",
		atomusdt: "아스트로코인",
		icpusdt: "ICP",
		uniusdt: "UNI",
	},
	currenctType: {
		title: "화폐를 선택하세요",
		currenctType6: 'USDT-ERC20',
		currenctType7: 'USDT-TRC20',
		currenctType8: 'BTC',
		currenctType13: 'ETH',
		currenctType14: 'ドル',
		currenctType15: '香港ドル',
		currenctType16: '台湾ドル',
		currenctType17: 'コイン枚',
		currenctType18: '新貨',
		currenctType19: '円',
		currenctType20: 'ユーロ',
		currenctType21: '豪ドル',
		currenctType22: 'インドネシア盾',
		currenctType23: 'ポンド',
		currenctType24: 'バーツ',
		currenctType25: 'かさん',
		currenctType26: 'ベトナムドン',
		currenctType27: '韓国ドル',
		currenctType28: 'マカオドル',
	},
	addnew2: {
		address: {
			account_type: "계정 유형",
			ifsc_code: "IFSC 코드",
			title: "지갑 주소",
			account_number: "계좌 번호",
			add_btn: "주소 추가",
			label_type: "유형 선택",
			place_type: "유형을 선택하세요",
			label_number: "계좌번호",
			place_number: "계좌번호를 입력하세요",
			wallet_address: '지갑 주소',
			bank_name: '은행 이름',
			bank_address: "은행 주소",
			name: "이름",
			payee_address: '수취인 주소',
			swift_code: "Swift/Swift Code/BIC",
			aba: "ABA",
			branch_number: "지점 이름",
			branch_code: "지점 코드",
			bank_code: "은행 코드",
			phone: "핸드폰 번호",
			iban: "IBAN",
			sort_code: "Sort Code",
			place_inner: "내용을 입력하십시오.",
		},
	},
	addnew: {
		paypassTit: "인출 비밀번호 수정",
		paypassTit1: "인출 비밀번호를 설정하십시오.",
		paypassTit2: "로그인 비밀번호로 비밀번호 초기화",
		loan: "대출",
		repaid: "이미 상환",
		unpaid: "미상환",
		loanMore: "대출 받으려고요",
		immediateRepayment: "대출 받으려고요",
		accountType1: "코인 계좌",
		accountType2: "계약 계정",
		accountType3: "옵션 계좌",
		from: "부터",
		to: "다음",
		get: "얻게 될 것이다",
		labelBreed: "화폐 종류",
		placeBreed: "코인을 선택하세요",
		labelTransAccount: "계좌이체",
		labelAmount: "이체 금액",
		placeAmount: "이체 금액을 입력하세요",
		transferTit: "계좌이체",
		convertTit: "화폐 환전",
		balanceTit: "계좌 잔액",
		available: "가용 한도",
		pending: "잠금",
		equivalent: "환산",
		coinTrade: "코인 거래",
		secondContract: "초 계약",
		number: "수량",
		labelNumber: "수량",
		placeNumber: "몸값 수량을 입력하세요",
		time: "시간",
		loadAmount: "예상 대출 금액",
		repaymentCycle: "대출 상환 주기",
		dailyRate: "일일 이율",
		repaymentMethod: "상환 방식",
		loanTip: "신용 대출 (그림이 잘 보이는지 확인하십시오)",
		loanTip1: "부동산 증서를 올리다",
		loanTip2: "소득증명(고용관계)",
		loanTip3: "은행 명세서 상세 정보",
		loanTip4: "주민등록증 정면 사진 올리기",
		interest: "취미",
		repaymentMethod1: "만기가 되면 한 번 상환한다.",
	},
	params: {
		product_foreign: "외화",
		product_shop: "상품",
		product_number: "지수",
		product_stock: "주식",
		product_coin: "암호화",
		billTypes: [
			{ v: 101, name: '충전' },
			{ v: 102, name: '추가' },
			{ v: 103, name: '동결' },
			{ v: 104, name: '해동' },
			{ v: 105, name: '예치' },
			{ v: 106, name: '예치' },
			{ v: 201, name: '현금 인출 동결' },
			{ v: 202, name: '공제' },
			{ v: 203, name: '현금 인출 성공' },
			{ v: 204, name: '현금 인출 실패' },
			{ v: 205, name: '현금 인출 수수료' },
			{ v: 206, name: '전출' },
			{ v: 207, name: '전입' },
			{ v: 208, name: '코인 전출' },
			{ v: 209, name: '코인 입금' },
			{ v: 301, name: '보증금 공제' },
			{ v: 302, name: '수수료 공제' },
			{ v: 303, name: '보증금 반환' },
			{ v: 304, name: '이익' },
			{ v: 305, name: '결손' },
			{ v: 311, name: '구매 옵션' },
			{ v: 312, name: '옵션 이익' },
			{ v: 313, name: '옵션 반환' },
			{ v: 314, name: '옵션 수수료' },
			{ v: 315, name: '코인 매입 동결' },
			{ v: 316, name: '코인 매입 공제' },
			{ v: 317, name: '코인 매입 반환' },
			{ v: 318, name: '코인 매입 입금' },
			{ v: 319, name: '코인 매입 입금' },
			{ v: 320, name: '코인 판매 동결' },
			{ v: 321, name: '코인 판매 공제' },
			{ v: 322, name: '코인 판매 반환' },
			{ v: 323, name: '화폐가 장부에 팔렸다.' },
			{ v: 324, name: '화폐가 장부에 팔렸다.' },
			{ v: 325, name: '코인 수수료' },
			{ v: 401, name: '광산 기계 가입' },
			{ v: 402, name: '광산 기계 귀환' },
			{ v: 403, name: '광산 기계 수입' },
			{ v: 404, name: '광산 기계 종료' },
			{ v: 405, name: '광산 기계 퇴출 수수료' },
			{ v: 411, name: '차관 수입' },
			{ v: 412, name: '대출 수수료' },
			{ v: 413, name: '상환 성공' },
		],
	},
	message: {
		logout: "로그인 종료",
		register_success: "등록 성공",
		place_inner_personal: "먼저 개인 정보를 입력하십시오.",
		submit_success: "커밋 성공！",
		copy_success: "복제 성공！",
		copy_error: "복제 실패！",
		modify_success: "수정 성공",
		no_balance: "잔액이 보증금을 지불하기에 부족하다.",
		palce_password: "암호를 입력하십시오.",
		trade_success: "거래 성공",
		placeEnterAmount: "금액을 입력하세요",
		tipTit1: "이 주문서를 지불하시겠습니까?",
	},
	web: {
		account: {
			menu1: "계정 정보",
			menu2: "내 자산",
			menu3: "계정 보고서",
			menu4: "계정 보안",
			menu5: "알림",
			menu6: "활동 센터",
			menu7: "내 지갑",
			menu8: "출금",
			walletAdd: "새 지갑 추가",
			report: {
				history_tit: "거래 내역 보고서",
				stream_tit: "자금 흐름 보고",
				desc: "적절한 날짜 범위를 선택하여 계정 보고서를 내보내십시오.(최근 1년 동안의 계정 정보를 확인할 수 있습니다.)",
				select_date: "날짜 범위 선택",
				week: "근 일주일",
				month: "근 한 달",
				threeMonth: "근 3개월",
				sixMonth: "근 반년",
				time: "시간",
				view_report: "보고서 표시",
				email_send: "이메일로 보내기",
				dialog_tit: "계정 보고서",
				dialog_p1: "CXMDirects Holding Ltd는 케이맨 제도(SIB 번호: 1612446)에 등록되어 거래 주체로 거래를 처리합니다.",
				tit2: "Mitade 거래 내역 보고서",
				tit4: "Mitade 자금 흐름 보고",
				tit3: "계정 정보",
				label1: "계정 이름",
				label2: "계정 ID",
				label3: "계좌 화폐",
				label4: "보고 시간",
				label5: "보고 기간",
				label6: "자금 흐름",
				empty: "당신은 이미 창고를 정리한 거래가 없습니다",
				email_des: "메일박스로 보고서 발송",
				tab1: "보고",
				tab2: "일 결산서",
				tab3: "월 결산서",
			},
			account: {
				tit1: "어서 오세요CXMDirects",
				tit2: "인증 완료 시 실제 입금 거래 가능",
				p1: "실제 거래 계정을 개설하다.",
				p2: "인증",
				btn1: "인증",
				tit3: "개인 정보",
				label_name: "이름",
				label_email: "이메일",
				label_address: "거주 주소",
				tit4: "계정 정보",
				label_account: "거래 계좌번호 (실제 거래)",
				label_currency: "기본 통화 (실제 거래)",
				label_company: "현재 계좌를 개설한 회사.",
				company_tip: "CXMDirects Holding Ltd,CXMDirects Holding Ltd CXMDirects 그룹 산하의 회사는 이미 케이맨 제도 금융 관리국 (CIMA) 의 허가와 권한을 얻었다。",
				tit5: "실제 거래 계정을 개설하다.",
				tit6: "자료를 완비하다",
				tit7: "프로파일 작성",
				tit8: "위험 평가",
				tit9: "거래 위험 감수성 평가",
				tit10: "인증",
				tit11: "규제 요건에 따라 인증 완료",
				p3: "다음 증명 파일 중 하나를 선택하여 확인하십시오.",
				type1: "신분증",
				type2: "운전면허증",
				type3: "여권",
				p4: "제공된 모든 정보와 파일이 완전하고 사실적이며 정확하다는 것을 확인했으며 업데이트가 발생하면 30일 이내에 CXMDirects에 업데이트 정보를 제공하겠다고 약속했습니다.",
				next: "다음 단계",
				btn2: "인증 통과",
				modify_personal_tit: "개인 정보 수정",
			},
			activity: {
				tit: "활동 센터",
				desc: "당신은 이벤트에 참여하여 보상을 수령하여, 당신의 투자 수익을 향상시킬 수 있습니다",
			},
			capital: {
				real_trade: "실제 거래",
				label_nowAccount: "현재 계정",
				label_tradeAccount: "거래 계정",
				label_currency: "기본 통화",
				deposit_withdraw_record: "출입금 기록",
				capital_flow_details: "자금 흐름 내역",
			},
			notification: {
				tit: "알림 설정",
				tit1: "알림 채널 열기",
				email: "이메일",
				message: "문자 메시지",
				push: "푸시",
				verify: "검증",
				verified: "검증됨",
				p1: "다음 알림 채널을 열거나 확인하십시오. 중요한 메시지를 놓치지 마십시오!",
				type1: "마케팅 클래스",
				tit2: "캠페인 알림",
				desc2: "플랫폼의 혜택 정보, 운영 활동 등 소식",
				tit3: "운영 지침",
				desc3: "풍부하고 전문적인 지식 자료",
			},
			security: {
				tit: "암호",
				isSet: "설정됨",
				set: "설정",
				verify: "검증",
				verified: "검증됨",
				tit1: "보안 검증",
				email: "이메일",
				phone: "핸드폰 번호",
				phone_tit: "핸드폰 번호 바인딩",
				password_tit: "암호 수정",
			},
		},
		market: {
			hot: "인기",
			prev: "이전 페이지",
			next: "다음 페이지",
			line: "시분할",
			main_subplot: "주 부도 지표",
			main: "마스터 맵 지표",
			subplot: "부도 지표",
			go_trade: "거래 페이지로 이동",
		},
		news: {
			tip: "면책성명: 상기 정보는 일반정보로서 참고용으로만 제공되며 거래결책의 의거로 삼지 않는다.",
		},
		trade: {
			state1: "창고 보유",
			state2: "청구서",
			state3: "역사",
			state: "상태",
			state4: "창고 정리됨",
			state5: "취소됨",
			type: "유형",
			type1: "모두",
			type2: "매도",
			type3: "매입",
			left_label1: "자유 선택",
			left_label2: "최근 탐색",
			left_type1: "축소 열 모드",
			left_type2: "여유 열 모드",
			left_type3: "동향도 표시",
			all: "모두",
			right_bp: "어제 수확",
			right_sp: "금수",
			right_height: "최고",
			right_low: "최소",
			remind_tit: "새 알림",
			remindList: "알림 목록",
			remind_btn: "새 알림",
			right_tab1: "거래",
			right_tab2: "상세 정보",
			right_sell: "매도",
			right_buy: "매입",
			right_type: "유형",
			right_type1: "시장 ",
			right_type2: "보류상태 ",
			right_priceMoney: "단가를 걸다",
			right_pointerNumber: "수량 (손)",
			right_lever: "레버",
			right_balance: "사용 가능한 잔액",
			right_stop_profit: "그치다",
			right_stop_loss: "손실 방지",
			right_profit_loss: "손익",
			now_order: "지금 주문",
			confirm_order: "주문 확인",
			right_tit1: "거래 전략",
			right_rinei: "일내",
			right_short: "단기",
			right_middle: "중기",
			right_time: "출시 날짜",
			right_tit2: "대체 정책",
			right_tit3: "기술 리뷰",
			right_tit4: "데이터",
			right_tit5: "거래 감정",
			right_label_sell: "판매자",
			right_label_buy: "구매자",
			right_tip: "참고로 제공할 뿐, 본사의 입장으로 삼지 않는다.",
			right_tip2: "15분마다 업데이트",
			right_tit6: "가격이 오르락내리락하다",
			minute: "분",
			today: "오늘",
			right_tit7: "가격 변화 구간",
			now_price: "현재 가격",
			right_low_price: "최저가",
			right_height_price: "최고가",
			right_tit8: "계약 정보",
			right_tit9: "호주 달러 대 릴랑",
			right_label1: "단일 거래 수",
			right_label2: "최대 레버",
			right_label3: "계약 크기",
			right_label4: "최대 총 보유 수량",
			right_label5: "변동 가격 차",
			right_label6: "하룻밤 요금",
			hand: "손",
			right_collection_time: "수취 시간",
			right_tip3: "요금 기준: 전체 거래 규모에서 수취",
			right_label7: "보증금 비율",
			right_label8: "보증금 비율 유지",
			right_label9: "거래 기간",
			right_label10: "현재 거래 기간",
			right_label11: "다음 거래 기간",


		},
	},
	home: {
		banner_p1: "통과",
		banner_p2: "플랫폼 거래",
		banner_p3: "외환, 상품, 지수, 주식",
		banner_p4: "및 더 많은 인기 금융 상품",
		banner_p5: "세계에서 가장 인기 있는 금융 시장을 탐색할 수 있습니다.CXMDirects는 0 커미션, 매우 유리한 저가 차이, 유연한 레버리지 구성을 제공합니다.",
		hot: "인기",
		tit1: "보다 우호적인 거래",
		p1: "단순하고 직관적인 인터페이스, 손쉬운 조작",
		p2: "웹 페이지, 모바일 앱 및 데스크탑을 지원하며 손끝에서 거래 가능",
		p3: "손상 방지 / 추적 손상 방지 등의 위험 관리 도구",
		p4: "다양한 기술 차트 및 경제 달력, 실시간 뉴스",
		p5: "실시간 메일, 메시지 및 푸시 알림",
		p6: "더욱 향상된 거래 환경 제공",
		btn1: "우리의 플랫폼 탐색",
		row3_tit: "언제 어디서나 자유롭게 거래 가능",
		row3_tip: "웹 페이지, 모바일 앱 및 데스크탑을 지원하며 손끝에서 거래 가능",
		row3_tit2: "스캔 다운로드",
		row3_tit3: "데스크톱",
		row3_tit4: "모바일 엔드",
		regular_tit: "신뢰할 수 있는 국제 플랫폼",
		regular_tip: "Dell은 고객에게 안전하고 책임 있는 거래 환경을 제공하기 위해 노력합니다.。",
		regular_tit1: "권위 있는 기구의 감독관리",
		regular_p1: "업계 최고 수준의 규제 및 권한 부여로 글로벌 고객 신뢰 확보",
		regular_tit2: "자금 보호",
		regular_p2: "소매고객의 예금은 감독관리법규의 요구에 따라 수요에 따라 단독으로 신탁구좌에 보관한다.",
		regular_tit3: "마이너스 잔액 보호",
		regular_p3: "귀하께 계좌 마이너스 잔액 보호를 제공하고, 마이너스 잔액은 제때에 청산하여, 귀하의 손실이 수입 금액을 초과하지 않도록 하며, 안심하고 거래할 수 있습니다.",
		regular_tit4: "5 * 24시간 고객 서비스",
		regular_p4: "24시간 온라인 지원을 통해 고객의 모든 문제를 해결하는 성실하고 전문적인 고객 서비스 팀",
		service_tit: "더 많은 서비스로 고객 지원",
		service_tit1: "거래 전략",
		service_p1: "실시간 거래 전략은 최신 시장 동향을 이해하고 거래 시기를 더 잘 파악할 수 있도록 도와줍니다.",
		service_tit2: "거래 지식",
		service_p2: "CXMDirects와 함께 거래 지식을 무료로 배우고 거래 기술을 향상시킵니다.",
		service_tit3: "위험 관리",
		service_p3: "CXMDirects가 제공하는 무료 위험 관리 도구를 통해 자산을 보다 효과적으로 보호할 수 있습니다.",
		step_tit: "계좌 개설이 간단하고 편리하다.",
		step_tip: "간단한 3단계로 몇 분 안에 계좌를 개설할 수 있다",
		step_tit1: "등록",
		step_p1: "정보를 작성하고 신청서를 제출하십시오.",
		step_tit2: "입금",
		step_p2: "여러 가지 방법으로 자금을 신속하게 예치할 수 있다.",
		step_tit3: "거래 시작",
		step_p3: "거래 기회 발굴 및 빠른 주문",
		award_tit: "우리 성적",
		award_p1: "우리는 줄곧 탁월함을 추구하고 더욱 정교함을 추구하며 고객에게 량질의 거래서비스를 제공하는데 진력해왔다.",
		award_p2: "CXMDirects 브랜드는 업계에서 유명한 기관으로부터 수상의 영예를 얻었으며 팀의 지속적인 노력과 고객에 대한 책임을 인정했습니다.",
		row7_tit: "실시간 글로벌 금융 정보",
		row7_tit1: "실시간 뉴스",
	},
	learn: {
		education: {

		}
	},
	login: {
		click_download: "다운로드 클릭",
		p1: "언제 어디서나 거래 가능",
		p2: "보다 빠르고 지능적인 거래",
		p3: "0커미션 거래",
		p4: "세계에서 가장 인기 있는 투자 품목",
		renderDom1: "외화",
		renderDom2: "미국 주식",
		renderDom3: "황금",
		renderDom4: "원유",
		renderDom5: "지수",
		renderDom6: "암호화폐",
		login: "로그인",
		elail: "메일박스",
		phone: "핸드폰 번호",
		no_account: "계정 없음？",
		go_register: "등록하러 가다",
		register: "등록",
		now_register: "지금 등록",
		have_account: "계정 있음？",
		go_login: "로그인",
	},
	about: {
		serviceFee: "서비스 요금",
		introduction: "플랫폼 소개",
		trade_platform: "거래 플랫폼",
		complaint_process: "불만 절차",
		page: "페이지",
		place_search: "원하는 항목 검색",
		no_answer: "원하는 답을 찾을 수 없습니다.?",
		contact_us: "연락처",
		awards: {
			h1: "장려 및 영예",
			p1: "우리는 줄곧 탁월함을 추구하고 더욱 정교함을 추구하며 고객에게 량질의 거래서비스를 제공하는데 진력해왔다.CXMDirects 브랜드는 업계에서 유명한 기관으로부터 수상의 영예를 얻었으며 팀의 지속적인 노력과 고객에 대한 책임을 인정했습니다.",
			p2: "또는 수상 경력에 빛나는 온라인 거래 플랫폼을 경험해 보십시오."
		},
		charges: {
			h1: "요금 및 비용",
			p1: "고객 경험을 향상시키는 구성 요소로서 CXMDirects는 고객에게 성가보다 더 나은 거래 서비스를 제공할 수 있기를 희망합니다.우리는 비용과 비용에 있어서 공개적이고 투명하며 숨은 비용을 포함하지 않는다.고객의 주요 거래 원가는 매매 가격차와 하룻밤의 창고 보유 이자이다.",
			h2: "먼저, CXMDirects를 사용하여 거래할 때",
			li1: "즉각적인 제품 견적",
			li2: "전문 기술 지표",
			li3: "독점 시장 분석",
			free: "무료",
			view: "위 보기",
			h3: "매매 가격 차",
			p2: "CXMDirects는 서비스 보상으로 매매 가격 차를 받습니다.이 부분의 비용은 이미 제품 견적에 반영되어 있으며, 당신이 창고를 세울 때 실제로 이미 차액을 지불했습니다.가격 차이가 일정하지 않으므로 특정 제품 견적 페이지를 방문하거나 에 로그인하는 것이 좋습니다.",
			h4: "예금과 인출 비용",
			p3: "고객의 비용을 절감하기 위해 대부분의 경우 귀하의 입출금에 대해 요금을 부과하지 않습니다.*그러나 제3자 회사 또는 은행은 귀하가 입출금하는 과정에서 수수료를 받을 수 있습니다. 예를 들어 중계은행 비용입니다.",
			p4: "요금이 필요한 상황에 대하여 저희 고객센터 직원에게 문의하실 수 있습니다.。",
			p5: "예를 들어 국제 신용카드 거래, 은행 계좌를 통한 전입/전출 또는 지원되지 않는 화폐를 이용한 거래(외화 전환) 등",
			h5: "격야 창고 보유 원가",
			p6: "그리니치 표준시 오후 10시 (GMT 22: 00) 에도 창고를 보유하면 콜 요금이 부과 / 입금됩니다.각 제품에 대한 콜 요금 조회는 특정 제품 견적 페이지로 이동하거나 에 로그인할 수 있습니다.",
			h6: "기타 비용",
			p7: "기타 비용은 없습니다.우리의 요금 정책은 완전히 투명하며, 요금이 필요한 모든 항목은 미리 공포하고 고지할 것이며, 보이지 않는 요금에 대해 걱정할 필요가 없다."
		},
		contact: {
			h1: "연락처",
			address: "주소",
			custom_email: "고객 연락처 이메일",
			business_email: "비즈니스 협력 이메일",
			h2: "우리에게 편지를 쓰다",
			p1: "만약에 귀하가 저희 서비스에 대해 궁금한 점이 있으시면 아래 양식을 작성하여 문제를 제출하시면 저희 고객센터 직원이 가능한 한 빨리 회답을 드리겠습니다.또한 Trading Central과 제휴하여 제공하는 일일 거래 전략을 신청하려면 아래 표 유형에서 '매일 시장 분석에 가입' 을 선택하고 원하는 언어 버전을 기입해 주십시오. *가입 서비스는 기존 CXMDirects의 실제 고객에게만 적용됩니다.",
			h3: "진술 절차",
			p2: "모든 불만 사항은 전문 팀이 조사하여 해결합니다. 자세한 내용은 참고하시기 바랍니다.",
		},
		faq: {
			h1: "자주 묻는 질문",
			tit1: "아날로그 계정이 무슨 소용이 있는지？",
			tit2: "실제 계좌를 개설하는 방법？",
			tit3: "어떻게 시가표를 세웁니까？",
			tit4: "왜 주문이 안 되나요?？",
			p1: "아날로그 계좌는 실제 계좌와 거래 인터페이스, 데이터, 조작에서 거의 대부분이 같다. 아날로그 계좌에는 5만 달러의 가상 자금이 있다. 그 목적은 고객이 자금 위험이 없는 상황에서 아날로그 조작을 통해 플랫폼의 각종 기능을 익히도록 하는 것이다.",
			p2: "실제 계좌를 개설하려면 아래 절차에 따라 조작하십시오.",
			p3: "1. CXMDirects 등록 페이지에 들어가면 알림에 따라 이메일/핸드폰 번호 등록, 로그인 비밀번호 설정, 확인 제출 후 자동으로 【아날로그 계정】을 만듭니다.",
			p4: "2. 아날로그 계정 페이지에서'실제 계정으로 전환'을 선택하고 절차에 따라 조작하면 【실제 계정】을 개설할 수 있습니다.",
			p5: "귀하가 실제 계좌를 개설하기 전에 저희는 귀하가 먼저 이해와 거래와 관련된 법률 공개 문서를 읽는 것을 권장합니다.",
			p6: "인증이 완료된 후에만 실제 계정에 입금할 수 있습니다.",
			p7: "거래(Trade)를 클릭하면 플랫폼에서 거래할 수 있는 모든 제품이 표시됩니다. 오른쪽 입력란에 품목 코드나 이름을 입력하여 검색할 수 있습니다. 한 제품을 선택하고'매수/매도'(SELL/BUY)를 클릭하면 거래 창이 팝업됩니다. 거래 창에서 현재 가격과 예상 보증금을 볼 수 있습니다.당신은 수동으로 창고 개설 거래 수량을 조절하고, 수익 정지와 손실 정지를 설정하여 위험 통제를 진행할 수 있으며, 아래의'매수/매도'(SELL/BUY) 를 클릭하면 창고 개설을 완료할 수 있습니다.모든 금융상품의 가격은 수시로 시장의 파동과 갱신을 따르고있으며 당신이 매입/매도 버튼을 누르기 전에 시세가 이미 변화되였을수도 있다는것을 주의하시기 바랍니다.",
			p8: "먼저 네트워크 연결이 올바른지 확인합니다.거래 품목 시장이 일시적으로 오퍼를 폐쇄하거나 휴장하면 주문할 수 없게 된다.자세한 내용은 온라인 고객 서비스에 문의하여 추가 지원을 요청하십시오.",

		},
		fundSecurity: {
			h1: "자금 안전",
			p1: "CXMDirects는 권위있는 규제 기관의 승인 및 규제를 받습니다.규정 준수는 모든 작업의 핵심이자 문화입니다.귀하의 자금 안전은 매우 중요하며 모든 고객의 예금은 격리되어 보관됩니다.",
			p2: "소매 고객의 예금은 규정 및 규정에 따라 필요한 경우 신탁 계좌에 별도로 보관됩니다.",
			p3: "CXMDirects는 법적 규정 및 라이선스 이외의 비즈니스 활동에 고객 자금을 사용하지 않습니다.",
			p4: "미트레이드는 어떤 시장에서도 투기를 하지 않습니다.",
			p5: "제3자 회계사 사무소 독립 감사",
		},
		help: {
			h1: "CXMDirects 도움말 센터에 오신 것을 환영합니다.",
		},
		media: {
			h1: "미디어 센터",
			p1: "CXMDirects 브랜드와 관련된 정보, 보도 자료, 미디어 클로즈업, 공지 및 기타 정보를 볼 수 있습니다.",
		},
		mitrade: {
			h1: "CXMDirects 정보",
			view_certificate: "（검증 방법）",
			p1: "CXMDirects는 투자자에게 간단하고 편리한 거래 경험을 제공하는 데 중점을 둔 권위 있는 기관이 규제 권한을 부여 한 금융 기술 회사입니다.혁신적인 CXMDirects 다원화 거래 플랫폼은 여러 차례 최고의 모바일 거래 플랫폼, 가장 혁신적인 증권사 등의 영예를 안았다.",
			p2: "CXMDirects 플랫폼을 통해 주식, 지수, 상품, 외환 등 수백 개의 인기 품목을 포함하여 더 광범위한 국제 금융 시장에 투자, 거래 할 수 있습니다.CXMDirects는 친근한 모바일 및 웹 거래 플랫폼뿐만 아니라 경쟁력 있는 거래 비용, 빠른 거래 실행, 우수한 고객 서비스 및 풍부한 정보 지원을 제공하여 투자, 거래의 좋은 기회를 적시에 포착할 수 있도록 지원합니다.",
			p3: "CXMDirects는 업계에서 권위 있는 여러 기관의 엄격한 규제를 받고 있습니다.CXMDirects International은 모리셔스 금융 서비스 위원회 (FSC) 의 승인을 받고 GB20025791 라이센스 번호로 규제됩니다.모리셔스 면허증에 대한 자세한 내용은 FSC 공식 웹 사이트를 참조하십시오.https://www.fscmauritius.org",
			p4: "질의를 진행합니다.MCXMDirects Global은 호주증권투자위원회(ASIC)가 승인한 금융서비스 번호판(AFSL 398528)을 보유하고 있다.호주 번호판에 대한 자세한 내용은 ASIC 공식 웹 사이트 www.asic.gov.au를 참조하십시오.",
			p5: "질의를 진행합니다.itrade Holding은 케이맨 제도 금융 관리국 (CIMA) 의 허가를 받고 규제를 받고 있으며 SIB 번호판 번호는 1612446이다.케이맨 면허증에 대한 자세한 내용은 CIMA 공식 웹사이트 www.cima.ky",
			p6: "질의를 진행합니다.모든 CXMDirects 비즈니스 운영은 엄격한 규제 하에 이루어지며 모든 규정을 준수합니다.",
			h2: "CXMDirects의 배경",
			p7: "CXMDirects는 금융 거래 및 금융 기술 산업에 대한 경험과 지식을 갖춘 베테랑 팀으로 호주 멜버른에서 설립되었습니다.CXMDirects의 이념은 거래가 더욱 간편하고 우호적으로 변하는 것이다",
			p8: "우리의 전체화 전략과 비즈니스 모델은 우리가 업계 발전과 시장 수요에 대해 전반적인 심도 있는 관찰과 인식을 가지게 함으로써 더욱 목적성 있는 자원 배치, 끊임없는 기술 혁신과 효율 최적화를 통해 고객에게 더욱 편리하고 우호적인 거래 체험을 지속적으로 가져다 줄 수 있다.​",
			h3: "CXMDirects를 선택해야 하는 이유",
			why1: "낮은 문턱의 거래 조건",
			why2: "업계 의 권위 있는 감독 을 받다",
			why3: "단순하고 직관적인 거래 플랫폼",
			why4: "수준 높은 온라인 지원",
			why5: "경쟁력 있는 거래 비용",
			why6: "마이너스 잔액 보호",
			h4: "상 및 영예",
			p9: "우리는 줄곧 탁월함을 추구하고 더욱 정교함을 추구하며 고객에게 량질의 거래서비스를 제공하는데 진력해왔다.",
			p10: "CXMDirects 브랜드는 업계에서 유명한 기관으로부터 수상의 영예를 얻었으며 팀의 지속적인 노력과 고객에 대한 책임을 인정했습니다.",
			new_add: "전 세계 신규 사용자",
			ol1: "최고의 뉴스 및 분석 정보 제공자",
			ol2: "아시아 최고의 외환권상",
			ol3: "호주 외환 고객 만족 대상",
			ol4: "최고의 모바일 어플리케이션",
			ol5: "호주에서 가장 빠르게 성장하는 쿠폰 업체",
			h5: "CXMDirects의 운영 철학",
			tip_tit1: "책임",
			tip_tit2: "간단하면서도 아름답다",
			tip_tit3: "투명",
			tip_tit4: "혁신",
			tip_p1: "모든 거래는 위험과 관련되며 CXMDirects는 고객에게 책임 있는 거래 환경을 제공하기 위해 더 많은 조치를 취합니다.CXMDirects는 산업 권위, 여러 기관의 엄격한 규제를 받고 있으며 높은 수준의 보안 및 안정성 운영 표준을 따르고 있습니다.Dell의 플랫폼은 위험 관리를 포함한 다양한 책임 있는 거래 수단을 지속적으로 제공하고 있습니다.",
			tip_p2: "단순함은 아름다움이고, 디테일은 탁월한 성과를 거두며, CXMDirects는 간편하고 사용하기 쉬우며 사용자 경험의 모든 디테일을 꾸준히 추구한다.우리는 지속적으로 플랫폼과 서비스를 최적화하여 초보자든 경험이 풍부한 투자자든 누구나 CXMDirects 플랫폼을 통해 거래의 좋은 기회를 쉽게 포착할 수 있기를 바랍니다.",
			tip_p3: "CXMDirects는 고객이 공정하고 효율적인 거래 환경에 충분히 혜택을 받을 수 있도록 엄격하고 투명한 자율 감독 체계를 준수하고 전면적이고 투명한 플랫폼 제품 정보, 가격 환경과 데이터를 제공하며 모든 비용을 일률적으로 명확하게 공개하여 전체 서비스 프로세스의 완전하고 진실하며 투명함을 확보합니다.",
			tip_p4: "CXMDirects는 과학 기술의 진보, 금융 혁신, 더 광범위한 서비스야말로 성장을 추진하는 힘이며, 끊임없이 가치를 창조하고 공유할 수 있다고 확신한다.이를 위해 우리는 개방적인 사고를 장려하고 혁신을 지속적으로 추진하여 핀테크의 혁신자와 선도자가 되기 위한 것이다.",
			row6_tit1: "우리의 사명",
			row6_tit2: "우리의 비전",
			row6_tit3: "일자리",
			row6_p1: "더 많은 거래를 하고 싶은 사람들에게 공간, 시간, 시장의 제한을 받지 않는 공평하고 효율적이며 투명한 거래 환경을 제공하여 업계의 금융과 과학기술 혁신을 선도한다.",
			row6_p2: "우리는 기술 진보, 금융 혁신 및 다양화 서비스가 우리의 성장을 촉진하는 힘이며 우리가 지속적으로 사회에서 가치를 창조하고 공유 할 수 있다고 믿습니다.",
			row6_p3: "이를 위해 우리는 개방적인 사고를 장려하고 혁신을 추진하며 끊임없는 성장과 진보를 통해",
			row6_p4: "세계 최고의 외환 회사",
			row6_p5: "우리는 7 개국에 걸친 글로벌 팀입니다. 팀이 빠르게 발전함에 따라 우리는 전문 인력을 찾아 우리에 합류했습니다.다문화 팀과 함께 좋아하는 일을 하세요!",
			row6_p6: "귀하의 최신 이력서와 함께 일하고자 하는 이유를 이메일로 보내 주십시오：",
			row6_p7: "。또한",
			row6_p8: "또는",
			row6_p9: "저희가 공개한 빈자리를 살펴보겠습니다.。",
			row6_p10: "CXMDirects는 개인 정보를 안전하게 보호합니다.지원 절차에서 CXMDirects는 재무 정보를 요청하지 않습니다.Google의 채용에는 재무 관련성, 신용카드, 은행 계좌 정보 또는 어떠한 형식의 지불도 필요하지 않습니다.",
			row6_p11: "직위에 사기 행위가 의심되는 경우",
			row6_p12: "CXMDirects HR 부서에 문의하십시오.허위 채용 정보에 속은 것으로 확인되면 해당 지역의 관련 부서에 문의하십시오.",

		}
	},
	product: {
		row2: {
			tit: "거래 품목",
			sell: "매도",
			buy: "매입",
			trade: "거래",
			more_product: "추가 거래 제품",
		},
		row3: {
			tit: "다양한 장치 거래 지원",
			tit1: "다양한 장치 거래 지원",
			p1: "CXMDirects는 웹 버전, 휴대 전화 및 데스크탑 거래 플랫폼을 제공합니다.언제 어디서나 거래할 수 있습니다.",
			download: "다운로드",
		},
		row4: {
			tit: "CXMDirects 선택",
			tit1: "단순하고 직관적인 플랫폼",
			p1: "시세, 거래, 계좌관리, 정보, 위험관리를 일체화하여 기능이 강대하고 더욱 좋은 사용체험을 실현한다",
			tit2: "경쟁력 있는 거래 비용",
			p2: "0 거래 수수료, 매우 경쟁력 있고 투명한 저가차, 낮은 콜금리로 저렴한 거래를 즐길 수 있습니다.",
			tit3: "낮은 문턱의 거래 조건",
			p3: "매번 최소 거래 건수가 0.01수로 낮고, 낮은 보증금으로 창고를 개설하여 거래할 수 있다",
			tit4: "권위 있는 감독 을 받다",
			p4: "업계 최고 수준의 권한 및 규제, 고객의 자산을 안전하게 보호하기 위해 모든 고객의 예금을 별도로 격리 보관",
			tit5: "마이너스 잔액 보호",
			p5: "어떠한 시황 하에서도 귀하의 계좌 손실은 원금을 초과하지 않으며, 마이너스 잔액은 즉시 청산하여 위험 관리 능력을 향상시킵니다",
			tit6: "수준 높은 온라인 지원",
			p6: "빠른 응답성을 제공하는 온라인 고객 서비스, 전문 팀의 지속적인 노력으로 더 나은 서비스 제공",
		},
		row5: {
			tit: "관련 문제",
		},
		commodities: {
			title: "상품 거래",
			p: "귀금속, 에너지 등 대종상품은 통화팽창을 막을수 있을뿐만아니라 투자증식의 기회도 있어 다원화투자의 중요한 구성부분으로 인정되고있다.이 중 금, 은, 석유 등은 거래량이 많은 상품 거래 품목으로 공개적이고 투명한 가격 및 높은 유동성으로 투자자들의 큰 인기를 끌고 있다.상품 가격은 공급과 수요, 경제와 정책 형세, 화폐 등 요소의 영향을 받아 현저하게 변동하여 위험 수익의 기회를 제공했다.",
		},
		forex: {
			title: "외환 거래",
			p: "외환거래는 한 통화를 다른 통화로 바꾸는 것으로, 외환시장은 전 세계에서 거래량이 가장 많은 금융시장이다.외환시장에는 많은 참여자가 있는데 외환시장을 통해 지불하거나 화폐의 변동 위험을 상쇄하거나 수익을 얻기 위해 거래를 한다.외환거래는 인터넷을 통해 전 세계의 은행과 기관, 개인 거래자 사이에서 매주 5일, 매일 24시간 가격 변동을 일으켜 위험 수익의 기회를 제공한다.",
		},
		indices: {
			title: "indices",
			p: "주식지수는 특정 거래소의 특정한 특성 주식을 대표하는 통계 수치로 시장에서 특정한 구성 주식의 전체 가격 수준과 변동을 반영하는 지표이다.주식 지수를 거래하는 것은 전체 시장이나 업종의 투자 기회를 반영하여 개별 주식에 투자하는 위험을 줄일 수 있다.호주 200, 미국 기술주 100, 홍콩 50, 독일 30 등 미국, 유럽, 아시아, 호주 시장에서 가장 인기 있는 지수는 글로벌 증시에 다른 기회를 제공한다.",
		},
		shares: {
			title: "주식 거래",
			p: "주식은 거래소에서 발행하는 일종의 유가증권이다.기업은 자금 조달을 위해 상장거래소를 통해 회사의 소유권을 증권을 이용해 분배한다.2019년에만 세계 주식의 거래 총액이 60조 달러를 넘어 규모가 크고 유동성이 강하기 때문에 여러 해를 겪어도 전 세계 투자자들에게 가장 인기 있는 금융 수단 중 하나다.CXMDirects 플랫폼에서 거래자는 레버리지를 사용하여 주식의 가격 변화를 거래 할 수 있습니다.주식 가격은 거시경제, 산업 전망과 회사 운영 등 여러 가지 요소의 영향을 받아 변동성이 크고 시시각각 투자 거래의 기회가 존재한다.",
		},
		coins: {
			title: "암호화폐",
			p: "암호화폐는 코드를 통해 생성된 디지털 화폐입니다. 기존 은행 및 정부 시스템 외부에서 자율적으로 운영됩니다. 이름에서 알 수 있듯이 암호화폐는 암호화를 사용하여 거래를 보호하고 다른 단위를 생성하는 데 사용되며, 비트코인은 사토시 나카모토가 2009년 1월에 만든 가장 유명한 암호화폐의 원조입니다. 오늘날에도 사토시 나카모토라는 이름이 한 사람을 지칭하는지, 아니면 여러 사람을 지칭하는지는 알려지지 않았습니다. 비트코인은 최초의 탈중앙화 암호화폐로 간주됩니다. 모든 암호화폐와 마찬가지로 분산형 공공 장부 역할을 하는 블록체인 거래 데이터베이스를 통해 통제됩니다. 현재까지 온라인에서 거래할 수 있는 암호화폐는 1,000개가 넘습니다."
		}
	},
	trade: {
		data_sources: "데이터 원본",
		tip: "위의 정보는 참조용으로만 제공됩니다.CXMDirects는 정보 콘텐츠의 정확성, 실시간 및 무결성을 보장하지 않으므로 제공된 정보에 지나치게 의존해서는 안 됩니다.이 정보에는 거래 가격 기록이나 금융 수단 거래의 청약 또는 모집이 포함되어 있지 않습니다.CXMDirects는 재무 조언을 제공하는 회사가 아니며 실행 명령 성격의 서비스만 제공합니다.우리는 독자들이 스스로 관련 투자 건의를 구할 것을 건의합니다.전체 면책 조항을 참조하십시오.",
		tip2: "매주 금요일 15:00(GMT) 업데이트",
		tip3: "거래 관점은 시장 거래 정서를 예측하는 도구로 각 인기 제품에 대한 업계 전문가들의 단기와 중기 견해와 추세 예측을 반영한다.",
		analysis: {
			tit: "거래 전략",
			all: "모두",
			day: "일내",
			tit1: "거래 전략",
			tit2: "기술 리뷰",
		},
		calendar: {
			tit: "경제 달력",
			prev_week: "지난 주",
			next_week: "다음 주",
			place_date: "검색 날짜",
			select: "옵션",
			select1: "국가",
			select2: "중요성",
			start_time: "시작 시간",
			end_time: "종료 시간",
			search: "검색",
		},
		contract: {
			tit: "계약 세칙",
			type1: "모두",
			type2: "외화",
			type3: "상품",
			type4: "지수",
			type5: "미국 주식",
			type6: "호주 주식",
			tit1: "주요 외환",
			tit2: "부차적인 외화",
			tit3: "상품",
			tit4: "주요 지수",
			tit5: "부차적인 지수",
			tit6: "미국 주식",
			tit7: "호주 주식",
			tit8: "일반 규칙",
			p1: "강평 비율",
			p2: "등기 유효기간",
			p3: "무제한",
			p4: "콜비 결제 시간",
			p5: "매일 결제하기;여름: 05:00, 겨울: 06:00",
			p6: "레버리지, 콜 요율 등 정보",
			p7: "거래 플랫폼 참조",
			p8: "위의 내용은 모든 거래 품목에 적용됩니다.",
			tit9: "거래 달력",
			p9: "휴장",
			p10: "이상의 거래 시간은 공휴일이나 시장 특수 조정의 영향을 고려하지 않았다.",
			p11: "구체적인 거래 시간 제안",
			p12: "로그인 플랫폼",
			p13: "，관련 품종의 소개에서 살펴보다。",
		},
		forecast: {
			tit: "거래 관점",
			p1: "평균값",
			p2: "오름세를 보이다",
			p3: "하락",
			p4: "횡반",
			p5: "경향",
		},
		news: {
			tit: "실시간 뉴스",
		},
		platforms: {
			tit: "CXMDirects거래 플랫폼",
			p: "단순하고 직관적인 인터페이스, 탁월한 거래 성능으로 더 많은 투자자의 요구를 충족시킬 수 있습니다.오늘부터 미트레이드에서 거래를 시작합시다.",
			tit1: "사용이 간편한 CXMDirects 거래 플랫폼",
			tit2: "언제 어디서나 CXMDirects App으로 거래 가능",
			li1: "수백 개의 인기 시장을 거래하고, 권위 있는 감독으로 자금 안전을 보장하다",
			li2: "실시간으로 시장 추세를 감시하고, 거래 분석을 보고, 최신 재경 뉴스를 훑어보다",
			li3: "언제든지 다양한 유형의 트랜잭션 차트를 검토하여 드로잉 기능 적용",
			li4: "Android 또는 iOS 장치에서 수상 경력이 있는 CXMDirects 거래 플랫폼 체험",
			code_download: "스캔 다운로드",
			tit3: "다운로드 없이 브라우저를 통해 온라인 거래",
			li5: "다운로드 없이 PC 브라우저에서 직접 온라인 거래",
			li6: "수상 경력: 2022 년 아시아 최고의 외환 브로커, 2022 년 호주 최고의 거래 플랫폼, 2022 년 세계에서 가장 빠르게 성장하는 외환 금융 기술 브로커",
			li7: "강력한 기술 지표 제공: MACD, KDJ, RSI, TRIX, DMA, CCI 등",
			li8: "손실 방지 및 마이너스 잔액 보호와 같은 위험 관리 도구를 통해 거래의 잠재적 위험 완화",
			tit4: "데스크탑 거래를 위한 완벽한 솔루션",
			li9: "선택 목록에서 시장 상황을 쉽게 확인",
			li10: "알림 기능을 켜고 가격 변경 알림 및 기타 정보 알림 수신",
			li11: "다양한 차트 레이아웃을 통해 다양한 시간 단위로 시장 동향을 추적할 수 있습니다.",
			li12: "계약 사양, 트랜잭션 감정 및 트랜잭션 분석이 동일한 인터페이스에 있으므로 전환 불필요",
			li13: "CXMDirects에서 최신 거래 뉴스를 직접 확인하십시오.",
			tit5: "왜 CXMDirects 거래 플랫폼을 선택해야 합니까?",
			row5_p1: "안전 보장",
			row5_p2: "0 커미션",
			row5_p3: "다중 플랫폼 지원",
			row5_p4: "기술 차트",
			row5_p5: "언제 어디서나 액세스 가능",
			row5_p6: "다양한 드로잉 도구",
		},
		quotes: {
			tit: "실시간 견적",
			p1: "현재 인기 있는 금융 품목의 즉각적인 오퍼, 시장 정서 및 관련 뉴스를 제공합니다.",
			hot: "인기",
		},
		risk: {
			tit: "위험 관리",
			p1: "모든 거래는 위험과 관련되어 있으며, CXMDirects가 제공하는 무료 위험 관리 도구를 유연하게 활용하여 시황의 순항에 관계없이 위험을 효과적으로 통제할 수 있다.",
			function_tit1: "손익 / 손실 방지 기능",
			function_tit2: "손상 방지 기능 추적",
			function_p1: "이윤을 고정시키다.",
			function_p2: "손실을 제한하다",
			function_p3: "최대화 고정 이윤",
			function_p4: "창고 자동 모니터링 불필요",
			function_p5: "결손 창고 정리 가격을 조정하다.",
			row2_tit1: "손익 / 손실 방지 기능",
			row2_p1: "신규 주문을 작성하거나 기존 주문을 수정할 때 '영업정지' 와 '손실정지' 를 설정할 수 있습니다.설정 후, 주문은 일반적으로 사용자가 설정한 목표 가격에 따라 창고를 정리하여 목표에 도달할 때 이윤을 잠그거나 시장 추세가 불리할 때 손실을 줄일 수 있습니다.어떤 주문이든 시황 변동으로 인해 품절될 수 있습니다. 이 경우 시스템은 기본 가격으로 주문을 수행하지 못하지만 목표 가격에서 다음 가장 유리한 가격으로 창고를 정리합니다.",
			row2_tit2: "예제",
			row2_p2: "유로/달러의 현재 가격은 1.13816/1.13837이다.1.13837에 1손 (1손 = 100000유로) 의 매입서를 작성하고 손실방지서를 1.13806으로 설정했습니다.",
			row2_p3: "일반적으로 가격이 1.13806까지 하락하면 손실방지서가 트리거되고 1.13806의 가격대에서 창고를 정리하여 도합 31딸라의 결손을 보게 된다.",
			row2_p4: "그러나 시장 환경이 돌변하여 가격이 1.13837에서 1.13795로 직접 하락하여 손실 방지 목표를 직접 건너뛸 때 시장이 도약하여 시스템은 1.13806에서 창고를 정리할 수 없지만 다음 가장 유리한 가격인 1.13795에서 창고를 정리할 것이며 최종 손실은 42달러가 될 것이다.",
			row3_tit1: "손상 방지 기능 추적",
			row3_tit2: "예제",
			row3_p1: "추적 손실 방지 (일명 모바일 손실 방지) 기능이 강력하며, 손실 방지 가격은 이윤에 따라 자동으로 변화할 수 있으며, 사용자가 항상 창고를 감시할 필요 없이 이윤을 극대화하거나 손실을 줄일 수 있다.새 주문을 작성할 때 추적 손상 방지 포인트를 설정하기만 하면 가격이 자신에게 유리한 방향으로 갈 때 손상 방지 주문이 최신 가격에 따라 자동으로 업데이트됩니다.반대로 가격이 당신에게 불리한 방향으로 변동하면 손실 방지 주문이 시작되고 당신이 설정한 손실 포인트 거리에서 창고를 정리합니다.어떤 주문이든 시장 변동으로 인해 품절될 수 있습니다. 이 경우 시스템은 기본 가격으로 주문을 수행하지 못하지만 목표 가격에서 가장 유리한 다음 가격으로 창고를 정리합니다.",
			row3_p2: "유로/달러의 기존 가격은 1.13816/1.13837 (판매/구매) 이다.1.13837에 1손의 매입서를 작성하고 100포인트(0.00100)의 추적 손실을 방지합니다.",
			row3_p3: "제품 가격이 1.13816일 때 귀하의 손실 방지 가격은 1.13716입니다.제품 판매 가격이 1.13845로 상승하면 손실 방지 가격은 사용자가 설정한 거리에 따라 갱신됩니다. 갱신된 손실 방지 가격은 1.13745입니다.",
			row3_p4: "반면 제품 가격이 1.13845에서 1.13745로 하락하면 추적 손실 방지가 촉발돼 1.13745에 창고를 정리한다.",
			tip: "점: 일반적으로 금융 수단 가격의 최소 변동 단위를 점이라고 합니다.CXMDirects 플랫폼에서는 도구 가격의 마지막 숫자 또는 소수를 의미합니다.",
		},
		sentiment: {
			tit: "정서지수",
			type1: "모두",
			type2: "외화",
			type3: "상품",
			type4: "지수",
			long: "다수",
			short: "공수",
		},
	},

	layout: {
		aside: {
			nav1: "거래",
			nav2: "시세",
			nav3: "정보",
			nav4: "학당",
			nav5: "나의",
			newsDialog: {
				title: "메시지 센터",
				type1: "시스템 알림",
				type2: "공지",
				allRead: "모두 읽음으로 표시",
			},
			settingDialog: {
				title: "설치",
				nav1: "일반",
				nav2: "표시 선호도",
				nav3: "시스템 정보",
				logout: "로그인 종료",
				setting1: "언어",
				setting2: "거래",
				setting2_tip: "창고 개설 품종 자동 추가 자체 선택",
				setting3: "피드백",
				setting3_tip: "기능 제안",
				setting4: "실명인증",
				setting4_tip: "실명인증",
				setting5: "테마 모드",
				setting5_label1: "어두운 색",
				setting5_label2: "밝은 색",
				setting6: "등락 색",
				setting6_label1: "녹색은 오르고 빨간색은 떨어지다",
				setting6_label2: "울긋불긋하다",
				setting7: "차트 설정",
				setting7_label1: "표준 버전",
				setting7_label2: "TradingView 버전",
				setting8: "선언 및 계약",
				setting8_label1: "개인 정보 보호 정책",
				setting8_label2: "제품 공개 선언",
				setting8_label3: "위험 공개 선언",
				setting8_label4: "고객 계약",
				feedback_title: "기능 제안",
			}
		},
		footer: {
			hot: "인기 품종",
			sort: "품종",
			code: "코드",
			buy_price: "매입 가격",
			sell_price: "판매 가격",
			chg: "등락폭",
		},
		header: {
			place_search: "거래 품목 검색",
			balance: "사용 가능한 잔액",
			balance_tip: "사용 가능한 잔액: 계좌에서 신규 창고 개설에 사용할 수 있는 금액",
			profit_loss: "손익",
			asset_view: "자산 프로필",
			netValue: "순가치",
			netValue_tip: "순자산: 현재 계정 가치, 모든 보유 손익 포함",
			marginLevel: "보증금 수준",
			margin: "보증금",
			margin_tip: "보증금: 개설 및 유지에 필요한 금액",
			maintainsMargin: "유지 보증금",
			maintainsMargin_tip: "유지 보증금: 귀하가 모든 창고를 보유할 때 계좌 내에서 유지해야 하는 최소 보증금 금액.",

		},
	},
	table: {
		label_name: "이름",
		label_buyPrice: "매입가",
		label_sellPrice: "매도 가격",
		label_variableValue: "변동치",
		trade: "거래",
		label_code: "제품 코드",
		label_title: "이름",
		label_start: "중요성",
		label_country: "국가",
		label_event: "이벤트",
		label_previous: "전치",
		label_predictive: "예측치",
		label_announced: "공표 값",
		label_stop_loss_min: "손상 방지표 최소 거리",
		label_difference: "동적 가격 차",
		label_contract_size: "계약 크기",
		label_max_hand: "최대 단일 거래 건수",
		label_min_hand: "최소 단일 거래 건수",
		label_trade_week: "거래 달력",
		label_week: "1주",
		label_month: "1월",
		label_quarter: "1분기",
		label_dailyTrend: "일일 추세",
		label_tradeId: "거래 ID",
		label_breed: "품종",
		label_tradeType: "거래 유형",
		label_currency: "화폐",
		label_amount: "금액",
		label_balance: "잔고",
		label_type: "유형",
		label_time: "시간",
		label_orderNumber: "주문 번호",
		label_pointer_number: "손수",
		label_price_money: "창고 개설 가격",
		label_stop_win_price: "그치다",
		label_stop_lose_price: "손실 방지",
		label_openTime: "창고 개설 시간",
		label_profit_loss: "손익",
		label_sell_buy: "매가 / 매가",
		label_chg: "등락폭",
		label_sell_price: "판매 가격",
		label_buy_price: "매입 가격",
		label_condition: "조건",
	},
	form: {
		label_feedback: "질문 및 제안",
		place_feedback: "질문 또는 권장 사항을 입력하십시오.",
		label_img: "그림",
		label_img_tip: "질문 캡처 기능을 제공하는 옵션",
		feedback_tip: "긴급한 문제가 있으면 문의하십시오.",
		online: "온라인 고객 서비스",
		label_name: "이름",
		place_name: "이름을 입력하십시오.",
		label_email: "이메일",
		place_email: "이메일 주소",
		message_email: "메일박스 입력",
		label_nationality: "국적",
		place_nationality: "국적을 입력하십시오.",
		place_questionType: "문제 유형",
		message_questionType: "유형 선택",
		questionType0: "거래 플랫폼",
		questionType1: "제품 및 요금",
		questionType2: "구독 일일 시장 분석",
		questionType3: "고객 서비스",
		questionType4: "기타",
		place_question: "질문",
		message_question: "질문을 입력하십시오.",
		submit_question: "문제 제출",
		label_phone: "핸드폰 번호",
		place_phone: "핸드폰 번호를 입력하세요.",
		message_phone: "핸드폰 번호를 입력하세요.",
		label_password: "암호",
		place_password: "암호를 입력하십시오.",
		message_password: "암호를 입력하십시오.",
		label_confirmPassword: "암호 확인",
		place_confirmPassword: "암호를 다시 입력하십시오.",
		message_confirmPassword: "암호를 다시 입력하십시오.",
		label_captcha: "인증 코드",
		place_captcha: "인증 코드를 입력하십시오.",
		message_captcha: "인증 코드를 입력하십시오.",
		get_captcha: "인증 코드 가져오기",
		label_inviteId: "초대 코드",
		place_inviteId: "초대 코드 (선택 사항)",
		to: "다음",
		start_time: "시작 시간",
		end_time: "종료 시간",
		label_cardNumber: "증명서 번호",
		place_cardNumber: "증명서 번호를 입력하십시오.",
		message_cardNumber: "증명서 번호를 입력하십시오.",
		label_cardMain: "증명서 정면",
		message_cardMain: "증명서 앞면을 입력하십시오.",
		label_cardBack: "증명서 뒷면",
		message_cardBack: "증명서 뒷면을 입력하십시오",
		confirm_modify: "수정 확인",
		label_realName: "전체 이름",
		place_realName: "이름을 입력하십시오.",
		message_realName: "전체 이름을 입력하십시오.",
		label_firstName: "성씨",
		place_firstName: "성을 입력하십시오.",
		message_firstName: "성을 입력하십시오.",
		label_lastName: "이름",
		place_lastName: "중간 이름을 입력하십시오.",
		message_lastName: "이름을 입력하십시오.",
		label_birthday: "생년월일",
		place_birthday: "생년월일을 입력하십시오.",
		message_birthday: "생년월일을 입력하십시오.",
		place_nowPsd: "현재 암호를 입력하십시오.",
		message_nowPsd: "현재 암호를 입력하십시오.",
		place_newPsd: "새 암호를 입력하십시오.",
		message_newPsd: "새 암호를 입력하십시오.",
		place_crmPsd: "새 비밀번호 확인",
		message_crmPsd: "새 비밀번호 확인",
		label_breed: "품종",
		place_breed: "품목을 입력하십시오.",
		label_phase: "저당",
		label_buyPrice: "매입 가격",
		label_sellPrice: "판매 가격",
		label_do: "다음",
		label_height: "보다 높음",
		label_low: "이하",
		label_equal: "또는 같음",
		labelWalletName: "지갑 이름",
		messageWalletName: "지갑 이름을 입력하십시오.",
		placeWalletName: "지갑 이름을 입력하십시오.",
		labelWalletAddress: "지갑 주소",
		messageWalletAddress: "지갑 주소를 입력하십시오.",
		placeWalletAddress: "지갑 주소를 입력하십시오.",
		labelAmount: "금액",
		messageAmount: "금액을 입력하세요",
		placeAmount: "금액을 입력하세요",
		placeTimeLimit: "시간 제한을 입력하십시오.",
		messageTimeLimit: "시간 제한을 입력하십시오.",
	},
	pay: {
		title: "출입금이 편리하다.",
		p1: "다양한 상용 지불 경로로 빠른 출입금 지원",
		p2: "일부 결제 방법은 해당 국가에서 사용할 수 없습니다.",
		p3: "CXMDirects 서비스에 오신 것을 환영합니다.",
		btn: "즉시 계좌를 개설하여 거래하다.",
	},
	header: {
		tip1: "레버리지 거래는 복잡한 금융 상품으로 신속한 손실의 높은 위험이 존재한다.",
		btn1: "거래 플랫폼",
		btn2: "로그인 종료",
		btn3: "로그인",
		BigUint64Array: "지금 거래",
	},
	footer: {
		help: "문의",
		tip1: "우리 커뮤니티 팔로우",
		tip2: "CXMDirects는 Telegram 공식 그룹을 만들지 않았으며 CXMDirects 이름으로 구성된 Telegram 그룹은 모두 사기 혐의가 있습니다.",
		tip3: "위험 경고: 거래로 인해 모든 자금이 손실될 수 있습니다.장외 파생상품 거래는 모든 사람에게 적합하지 않다.서비스를 사용하기 전에 법률 문서를 자세히 읽고 거래 전에 관련된 위험을 충분히 이해하십시오.귀하는 관련 기초 자산을 실제로 소유하거나 보유하지 않습니다.",
		tip4: "CXMDirects는 구매, 보유 또는 판매에 대한 제안, 추천 또는 의견을 제공하지 않습니다.우리가 제공하는 모든 제품은 글로벌 자산을 기반으로 하는 장외 파생상품이다.CXMDirects에서 제공하는 모든 서비스는 트랜잭션 명령 실행에 기반합니다.",
		tip5: "CXMDirects는 여러 회사가 공동으로 사용하는 비즈니스 브랜드로, 다음 회사를 통해 운영됩니다.",
		tip6: "CXMDirects International Ltd는 이 웹 사이트에서 설명하거나 사용 가능한 금융 상품의 발행인입니다.CXMDirects International Ltd는 모리셔스 금융 서비스 위원회 (FSC) 의 승인을 받았으며 GB20025791 라이센스 번호, 등록 주소: 6 St Denis Street, 1st Floor River Court, Port Louis 11328, Mauritius",
		tip7: "미트레이드 글로벌 Pty Ltd 등록번호는 ABN 90 149 011 361, 호주 금융서비스번호판(AFSL) 번호는 398528이다.",
		tip8: "CXMDirects Holding Ltd는 1612446의 SIB 번호판 번호로 케이맨 제도 금융 관리국 (CIMA) 의 승인을 받고 규제를 받고 있습니다.",
		tip9: "본 사이트의 정보는 미국, 캐나다, 일본, 뉴질랜드 주민을 대상으로 하지 않습니다.이 웹 사이트 정보는 게시 또는 사용으로 인해 현지 법률 또는 규정을 위반한 국가 또는 관할 구역의 누구에게도 적합하지 않습니다.영어는 저희 서비스에서 사용하는 주요 언어이며 저희 모든 계약 서류에 법적 효력이 있는 언어입니다.기타 언어 번역본은 참고용으로만 제공되며, 문역이 만약 영어와 다른 뜻이 있다면, 모두 영어를 기준으로 한다.",
		tip10: "SSL 보안 통신 암호화.© CXMDirects 저작권 소유, 모든 권리 보유.",
		link1: "개인 정보 보호 정책",
		link2: "제품 공개 선언",
		link3: "불만 절차",
		link4: "위험 공개 선언",
		link5: "고객 계약",
		toTop: "상단",
	},
	nav: {
		tit1: "제품",
		tit2: "거래",
		tit3: "투자 학습",
		tit4: "우리에 대해",
		nav1: "외화",
		nav2: "지수",
		nav3: "상품",
		nav4: "주식",
		nav5: "거래 플랫폼",
		nav6: "거래 전략",
		nav7: "거래 관점",
		nav8: "경제 달력",
		nav9: "실시간 뉴스",
		nav10: "실시간 견적",
		nav11: "정서지수",
		nav12: "위험 관리",
		nav13: "계약 세칙",
		nav14: "투자 시작",
		nav15: "투자 혜안",
		nav16: "Academy",
		nav17: "정보 CXMDirects",
		nav18: "상 및 영예",
		nav19: "미디어 센터",
		nav20: "자금 안전",
		nav21: "요금 및 비용",
		nav22: "Affiliates",
		nav23: "연락처",
		nav24: "자주 묻는 질문",
		nav25: "도움말 센터",
	},

	city: {
		albania: "알바니아",
		algeria: "알제리",
		argentina: "아르헨티나",
		armenia: "아르메니아",
		australia: "호주",
		pakistan: "파키스탄",
		austria: "오스트리아",
		bahrain: "바레인",
		belgium: "벨기에",
		bosnia_and_Herzegovina: "보스니아 헤르체고비나",
		brazil: "브라질",
		brunei: "브루나이",
		bulgaria: "불가리아",
		cambodia: "캄보디아",
		canada: "캐나다",
		cameroon: "카메룬",
		chile: "칠레",
		colombia: "콜롬비아",
		costa_Rica: "코스타리카",
		croatia: "크로아티아",
		cyprus: "키프로스",
		czech_Republic: "체코",
		denmark: "덴마크",
		dominican_Republic: "도미니카",
		egypt: "이집트",
		estonia: "에스토니아",
		ethiopia: "에티오피아",
		finland: "핀란드",
		france: "프랑스",
		georgia: "그루지야",
		germany: "독일",
		ghana: "가나",
		greece: "그리스",
		guyana: "가이아나",
		honduras: "온두라스",
		hong_Kong_China: "중국 홍콩",
		hungary: "헝가리",
		iceland: "아이슬란드",
		ireland: "아일랜드",
		italy: "이탈리아",
		india: "인도",
		indonesia: "인도네시아",
		israel: "이스라엘",
		iran: "이란",
		iraq: "이라크",
		japan: "일본",
		kazakstan: "카자흐스탄",
		kenya: "케냐",
		korea: "한국",
		kuwait: "쿠웨이트",
		kyrgyzstan: "키르기스스탄",
		laos: "라오스",
		Latvia: "라트비아",
		lithuania: "리투아니아",
		luxembourg: "룩셈부르크",
		macao_China: "중국 마카오",
		macedonia: "마케도니아",
		malaysia: "말레이시아",
		malta: "몰타",
		mexico: "멕시코",
		moldova: "몰도바",
		monaco: "모나코",
		mongolia: "몽골",
		montenegro: "몬테네그로",
		morocco: "모로코",
		myanmar: "미얀마",
		netherlands: "네덜란드",
		new_Zealand: "뉴질랜드",
		nepal: "네팔",
		nigeria: "나이지리아",
		norway: "노르웨이",
		oman: "오만",
		palestine: "팔레스타인",
		panama: "파나마",
		paraguay: "파라과이",
		peru: "페루",
		philippines: "필리핀",
		poland: "폴란드",
		portugal: "포르투갈",
		puerto_Rico: "푸에르토리코",
		qatar: "카타르",
		romania: "루마니아",
		russia: "러시아",
		republic_of_Trinidad_and_Tobago: "트리니다드 토바고",
		rwanda: "르완다",
		saudi_Arabia: "사우디아라비아",
		serbia: "세르비아",
		singapore: "싱가포르",
		slovakia: "슬로바키아",
		slovenia: "슬로베니아",
		south_Africa: "남아프리카",
		spain: "스페인",
		sri_Lanka: "스리랑카",
		sweden: "스웨덴",
		switzerland: "스위스",
		taiwan_China: "대만",
		tajikistan: "타지키스탄",
		tanzania: "탄자니아",
		thailand: "태국",
		turkey: "터키",
		turkmenistan: "투르크메니스탄",
		ukraine: "영국",
		united_Arab_Emirates: "미국",
		united_Kingdom: "우즈베키스탄",
		united_States: "베네수엘라",
		uzbekistan: "베트남",
		venezuela: "아프가니스탄",
		vietnam: "베트남",
		afghanistan: "아프가니스탄",
		angola: "앙골라",
		azerbaijan: "아제르바이잔",
		bangladesh: "방글라데시",
		belarus: "벨라루스",
		belize: "벨리즈",
		benin: "베냉",
		bhutan: "부탄",
		bolivia: "볼리비아",
		botswana: "보츠와나",
		british_Virgin_Islands: "영국령 버진 아일랜드",
		burkina_Faso: "부르키나파소",
		burundi: "부룬디",
		cape_Verde: "카보베르데",
		cayman_Islands: "케이맨 제도",
		central_African_Republic: "중앙아프리카",
		chad: "차드",
		comoros: "코모로",
		the_Republic_of_the_Congo: "콩고 (부)",
		democratic_Republic_of_the_Congo: "콩고 (김)",
		djibouti: "지부티",
		ecuador: "에콰도르",
		el_Salvador: "엘살바도르",
		equatorial_Guinea: "적도 기니",
		eritrea: "에리트레아",
		fiji: "피지",
		gabon: "가봉",
		gambia: "감비아",
		greenland: "그린란드",
		guatemala: "과테말라",
		guinea: "기니",
		haiti: "아이티",
		isle_of_Man: "맨 섬",
		cote_d_Ivoire: "코트디부아르",
		jamaica: "자메이카",
		jordan: "요르단",
		lebanon: "레바논",
		lesotho: "레소토",
		liberia: "라이베리아",
		libya: "리비아",
		madagascar: "마다가스카르",
		malawi: "말라위",
		maldives: "몰디브",
		mali: "말리",
		mauritania: "모리타니",
		mauritius: "모리셔스",
		mozambique: "모잠비크",
		namibia: "나미비아",
		nicaragua: "니카라과",
		republic_of_Niger: "니제르",
		north_Korea: "조선",
		reunion: "레위니옹",
		san_Marino: "산마리노",
		senegal: "세네갈",
		sierra_Leone: "시에라리온",
		somalia: "소말리아",
		sudan: "수단",
		suriname: "수리남",
		eswatini: "스와질란드",
		syria: "시리아",
		togo: "토고",
		tonga: "통가",
		tunisia: "튀니지",
		united_States_Virgin_Islands: "미국령 버진 아일랜드",
		uganda: "우간다",
		uruguay: "우루과이",
		vatican: "바티칸",
		yemen: "예멘",
		yugoslavia: "유고슬라비아",
		zambia: "잠비아",
		zimbabwe: "짐바브웨",
		china: "중국",
	}
};
