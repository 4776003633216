<template>
  <router-view></router-view>
</template>
<script>
export default {
  mounted() {
    // if (this._isMobile()) {
    //   if(this.$route.path !== "/") {
    //     window.location.href = "https://mobile.Deribit.com/";
    //   }
    // }
    const theme = window.localStorage.getItem("theme") || "dark";
    document.body.classList.add(theme);
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="less">
body {
  --primary-B1: #006cf6;
  --primary-B2: #0067ea;
  --primary-B3: #99c4fb;
  --primary-B4: rgba(0, 108, 246, 0.6);
  --primary-B5: rgba(0, 108, 246, 0.18);
  --primary-B6: rgba(0, 108, 246, 0.08);
  --primary-B7: rgba(0, 108, 246, 0.04);
  --primary-B8: #e0edff;
  --semantic-G1: #34c759;
  --semantic-G2: rgba(52, 199, 89, 0.08);
  --semantic-R1: #ff3b30;
  --semantic-R2: rgba(255, 59, 48, 0.08);
  --semantic-O1: #ff671f;
  --semantic-O2: rgba(255, 103, 31, 0.08);
  --semantic-O3: rgba(255, 103, 31, 0.6);
  --semantic-Y1: #ffae00;
  --semantic-Y2: rgba(255, 174, 0, 0.08);
  --background-W1: #f1f3f6;
  --background-W2: #ffffff;
  --background-W3: #f6f6f7;
  --background-W4: #ffffff;
  --background-W5: rgba(255, 255, 255, 0.94);
  --background-W6: #ececec;
  --background-W7: #000000;
  --background-W8: #ffffff;
  --background-W9: rgba(0, 0, 0, 0.7);
  --background-W10: #4d73b1;
  --background-W11: rgba(0, 0, 0, 0.6);
  --background-W12: rgba(60, 63, 67, 0.18);
  --background-W13: rgba(250, 252, 255, 0.6);
  --background-W14: rgba(0, 108, 246, 0.02);
  --background-W15: #ececec;
  --background-W16: rgba(60, 63, 67, 0.04);
  --background-W26: #393d43;
  --up-down-P1: #009e4a;
  --up-down-P2: rgba(0, 158, 74, 0.08);
  --up-down-P3: linear-gradient(
    0deg,
    rgba(0, 158, 74, 0) 0%,
    rgba(0, 158, 74, 0.08) 100%
  );
  --up-down-P4: rgba(255, 255, 255, 0);
  --up-down-P5: rgba(0, 158, 74, 0.08);
  --up-down-L1: #f6223c;
  --up-down-L2: rgba(246, 34, 60, 0.08);
  --up-down-L3: linear-gradient(
    0deg,
    rgba(246, 34, 60, 0) 0%,
    rgba(246, 34, 60, 0.08) 100%
  );
  --up-down-L4: rgba(255, 255, 255, 0);
  --up-down-L5: rgba(246, 34, 60, 0.08);
  --font-icon-T0: #ffffff;
  --font-icon-T1: #000000;
  --font-icon-T2: rgba(60, 63, 67, 0.6);
  --font-icon-T3: rgba(60, 63, 67, 0.3);
  --font-icon-T4: rgba(60, 63, 67, 0.18);
  --font-icon-T5: #006cf6;
  --font-icon-T6: rgba(120, 120, 128, 0.16);
  --font-icon-T7: #000000;
  --brand-S1: #0147bb;
  --chart-data-C1: #00c7be;
  --chart-data-C2: #30b0c7;
  --chart-data-C3: #32ade6;
  --chart-data-C4: #5856d6;
  --chart-data-C5: #f964d1;
  --chart-data-C6: #af52de;
  --chart-data-C7: #ff2d55;
  --chart-data-C8: #a2845e;
  --chart-data-C9: #8e8e93;
  --chart-data-C10: #aeaeb2;
  --chart-data-C11: linear-gradient(
    180deg,
    rgba(133, 133, 135, 0.08) 0%,
    rgba(133, 133, 135, 0.6) 50%,
    rgba(133, 133, 135, 0.08) 100%
  );

  --el-text-color-placeholder: #c0c4cc;
  --el-font-size-larger: 18px;
  --el-font-size-large: 16px;
  --el-font-size-medium: 14px;
  --el-font-size-base: 12px;
  --el-font-size-small: 12px;
  --el-font-size-extra-small: 12px;
  --el-table-border-color: #f2f5f7;
  --cc-loss-area-color: #fef5f8;
  --cc-profile-area-color: #e7f3f5;
  --cc-favorite-symbol-color: #fcbc44;
  --cc-default-btn-bg: #494f59;
  --cc-default-btn-bg-disabled: #58606b;
  --cc-font-size-over-large: 18px;
  --cc-text-font-weight: 600;
  --cc-text-font-weight-bold: 700;
  --cc-font-size-11: 11px;
  --cc-font-size-9: 9px;
  --cc-font-size-32: 32px;
  --cc-font-size-24: 24px;
  --cc-font-size-20: 20px;
  --cc-color-white: #ffffff;
  --cc-color-black: #000000;
  --cc-font-size-13: 13px;
  --dialog-shadow-color: #ffffffba;
  --cc-font-size-17: 17px;
  --cc-bg-color-20: #8996aa;
  --cc-close-btn-color: #fc4144;
  --color-h1: 0 21px 78px 0 #060606;
  --font-icon-t0: #ffffff;
  --font-icon-t6: #78788029;
  --background-w17: #000000;
  --background--w20: #ff8996aa;
  --color-c11: linear-gradient(
    180deg,
    #85858714 0%,
    #85858799 50%,
    #85858714 100%
  );
  --root-color-scheme: light;
  --el-background-color-base: #f5f7fa;
  --el-color-white: #fcfdff;
  --el-color-black: #ffffff;
  --el-border-color-base: #dcdfe6;
  --el-border-color-light: #e4e7ed;
  --el-border-color-lighter: #ebeef5;
  --el-border-color-extra-light: #f2f6fc;
  --el-color-info: #909399;
  --el-fill-color-blank: #f6f6f7;
  --el-disabled-bg-color: #f5f7fa;
  --cc-tag-bg-color: #f3f5f7;
  --cc-drag-bg-color: #f2f5f7;
  --cc-ghost-bg-color: #afd8e5;
  --cc-input-append-bg-color: #f5f7fa;
  --cc-btn-disabled-color: #e7e9eb;
  --update-left-bgcolor: #f6faff;
  --update-scan-qr-border: #78788029;
  --background-w1: #f1f3f6;
  --background-w2: #ffffff;
  --background-w3: #f6f6f7;
  --background-w4: #ffffffff;
  --background-w5: #fffffff0;
  --background-w6: #dfe0ea;
  --background-w7: #000000;
  --background-w8: #ffffff;
  --background-w9: #000000b3;
  --background-w10: #4d73b1;
  --background-w11: #00000099;
  --background-w12: #3c3f432e;
  --background-w13: #fafcff99;
  --background-w14: #006cf605;
  --background-w15: #e9ebedff;
  --background-w16: #1e232c0f;
  --background-w18: #4a4847;
  --background-w19: #fcfdff;
  --background-w21: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 26%,
    rgba(255, 255, 255, 0) 100%
  );
  --background-w22: #f7f7f8;
  --background-w23: #eaeaeb;
  --background-w24: linear-gradient(90deg, #343434ff 0%, #434343ff 100%);
  --background-w25: #f4f6fc;
  --background-w26: #313944;
  --background-w27: #ffffff;
  --background-w28: #f6f6f7ff;
  --background-w29: #ffffff;
  --background-w30: #f6f6f7;
  --font-icon-t1: #000000;
  --font-icon-t2: #3c3f4399;
  --font-icon-t3: #3c3f434d;
  --font-icon-t4: #3c3f432e;
  --font-icon-t5: #006cf6;
  --font-icon-t7: #000000ff;
  --font-icon-t8: #ffffcfb2;
  --color-b1: #006cf6;
  --color-b2: #0067ea;
  --color-b3: #99c4fb;
  --color-b4: #006cf699;
  --color-b5: #006cf63d;
  --color-b6: #006cf614;
  --color-b7: #006cf60a;
  --color-b8: #e0edff;
  --color-b9: linear-gradient(90deg, #006cf6ff 0%, #2c85f6ff 100%);
  --color-g1: #34c759;
  --color-g2: #34c75914;
  --color-r1: #ff3b30;
  --color-r2: #ff3b3014;
  --color-r3: #ff3b300a;
  --color-r4: #ffccc9ff;
  --color-r5: #ff8680ff;
  --color-o1: #ff671f;
  --color-o2: #ff671f14;
  --color-o3: #ff671f14;
  --color-o4: #ffebe0;
  --color-y1: #ffae00;
  --color-y2: #ffae0014;
  --color-c1: #00c7be;
  --color-c2: #30b0c7;
  --color-c3: #32ade6;
  --color-c4: #5856d6;
  --color-c5: #f964d1;
  --color-c6: #af52de;
  --color-c7: #ff2d55;
  --color-c8: #a2845e;
  --color-c9: #8e8e93;
  --color-c10: #aeaeb2;
  --color-p1: #0b9981;
  --color-p2: #009e4a14;
  --color-p3: linear-gradient(0deg, #009e4a00 0%, #009e4a14 100%);
  --color-p4: #ffffff00;
  --color-p5: #009e4a14;
  --color-p6: linear-gradient(90deg, #009e4a00 0%, #009e4a0a 100%);
  --color-p7: #90a399;
  --color-l1: #f23645;
  --color-l2: #eb42561f;
  --color-l3: linear-gradient(0deg, #f6223c00 0%, #f6223c14 100%);
  --color-l4: #ffffff00;
  --color-l5: #f6223c14;
  --color-l6: linear-gradient(90deg, #f6223c00 0%, #f6223c0a 100%);
  --color-l7: #c2aaadff;
  --color-h2: 0 3px 13px 0 rgba(0, 0, 0, 0.17);
  --cc-loss-hover: #fd8284;
  --cc-profit-hover: #8edda5;
  --cc-bg-color-2-mask: #fffffff2;
  --cc-bg-color-2-00: #ffffff00;
  --cc-gradient-bg-color: #f6f6f700;
  --el-text-color-primary: #000000;
  --el-checkbox-checked-text-color: #000000;
  --el-checkbox-text-color: #000000;
  --el-disabled-text-color: #00000066;
  --el-text-color-regular: #000000;
  --el-text-color-secondary: #3c3f4399;
  --el-color-primary: #006cf6;
  --cc-color-theme-color: #ffffff;
  --cc-reverse-color: #000000;
  --cc-table-hover-color: #f7f7f8;
  --cc-checkbox-disabled: #98c7f9;
  --dialog-box-shadow: 0 94px 232px 0 rgba(0, 0, 0, 0.33),
    0 39.27px 96.92px 0 rgba(0, 0, 0, 0.24), 0 21px 51.82px 0 rgba(0, 0, 0, 0.2),
    0 11.77px 29.05px 0 rgba(0, 0, 0, 0.17),
    0 6.25px 15.43px 0 rgba(0, 0, 0, 0.13), 0 2.6px 6.42px 0 rgba(0, 0, 0, 0.09);
  --play-icon-bg-color: #00000080;
  --el-color-primary-light-9: #e3effe;
  --el-color-primary-light-8: #cae0fd;
  --el-color-primary-light-7: #b0d2fc;
  --el-color-primary-light-6: #97c3fb;
  --el-color-primary-light-5: #7eb5fb;
  --el-color-primary-light-4: #65a6fa;
  --el-color-primary-light-3: #4c98f9;
  --el-color-primary-light-2: #3289f8;
  --el-color-primary-light-1: #197bf7;

  --el-text-color-placeholder: #c0c4cc;
  --el-font-size-large: 16px;
  --el-font-size-medium: 14px;
  --el-font-size-base: 12px;
  --el-font-size-small: 12px;
  --el-font-size-extra-small: 12px;
  --font-size-24: 24px;
  --el-table-border-color: #f2f5f7;
  --cc-loss-color: #fa4d4f;
  --cc-loss-area-color: #fef5f8;
  --cc-profit-color: #44c668;
  --cc-profile-area-color: #e7f3f5;
  --cc-favorite-symbol-color: #fcbc44;
  --cc-loss-hover: #fd8284;
  --cc-profit-hover: #8edda5;
  --cc-default-btn-bg: #494f59;
  --cc-default-btn-bg-disabled: #58606b;
  --cc-font-size-over-large: 18px;
  --cc-error-info-color: #fa4d4f;
  --font-weight-500: 600;
  --root-color-scheme: light;
  --el-background-color-base: #f5f7fa;
  --el-color-white: #ffffff;
  --el-bg-color: #ffffff;
  --el-color-black: #ffffff;
  --el-color-primary: #409eff;
  --el-text-color-primary: #303133;
  --el-text-color-regular: #606266;
  --el-text-color-secondary: #909399;
  --el-border-color-base: #dcdfe6;
  --el-border-color-light: #e4e7ed;
  --el-border-color-lighter: #ebeef5;
  --el-border-color-extra-light: #f2f6fc;
  --el-color-info: #909399;
  --cc-border-color-wrap: #e6ebf0;
  --cc-border-bottom-regular: #ecf0f3;
  --cc-drag-bg-color: #f2f5f7;
  --el-fill-color-blank: #ffffff;
  --cc-text-color-primary: #606266;
  --cc-tag-bg-color: #f3f5f7;
  --cc-table-hover-bg-color: #f0f2f0;
  --el-disabled-bg-color: #f5f7fa;
  --cc_dropdown-bg-color: #e6e5e4;
  --cc-bg-color-light: #f3f5f8;
  --cc-input-bg-color: #fafafb;
  --cc-scroll-thumb-color: #e6ebf0;
  --cc-scroll-thumb-hover-color: #e3e3e3;
  --cc-checkbox-border-color: #dcdfe6;
  --cc-dialog-border-color: #8d8d8d;
  --cc-dropdown-border-color: #c3c6cc;
  --cc-ghost-bg-color: #afd8e5;
  --cc-input-append-bg-color: #f5f7fa;
  --cc-btn-disabled-color: #ffffff;
  --primary-B1: #006cf6;
  --primary-B2: #0067ea;
  --primary-B3: #99c4fb;
  --primary-B4: rgba(0, 108, 246, 0.6);
  --primary-B5: rgba(0, 108, 246, 0.18);
  --primary-B6: rgba(0, 108, 246, 0.08);
  --primary-B7: rgba(0, 108, 246, 0.04);
  --semantic-G1: #34c759;
  --semantic-G2: rgba(52, 199, 89, 0.08);
  --semantic-R1: #ff3b30;
  --semantic-R2: rgba(255, 59, 48, 0.08);
  --semantic-O1: #ff671f;
  --semantic-O2: rgba(255, 103, 31, 0.08);
  --semantic-O3: rgba(255, 103, 31, 0.6);
  --semantic-Y1: #ffae00;
  --semantic-Y2: rgba(255, 174, 0, 0.08);
  --background-W1: #f1f3f6;
  --background-W2: #ffffff;
  --background-W3: #f6f6f7;
  --background-W4: #ffffff;
  --background-W5: rgba(255, 255, 255, 0.94);
  --background-W6: #ececec;
  --background-W7: #000000;
  --background-W8: #ffffff;
  --background-W9: rgba(0, 0, 0, 0.7);
  --background-W10: #4d73b1;
  --background-W11: rgba(0, 0, 0, 0.6);
  --background-W12: rgba(60, 63, 67, 0.18);
  --background-W13: rgba(250, 252, 255, 0.6);
  --background-W14: rgba(0, 108, 246, 0.02);
  --background-W15: #ececec;
  --background-W16: rgba(60, 63, 67, 0.04);
  --up-down-P1: #009e4a;
  --up-down-P2: rgba(0, 158, 74, 0.08);
  --up-down-P3: linear-gradient(
    0deg,
    rgba(0, 158, 74, 0) 0%,
    rgba(0, 158, 74, 0.08) 100%
  );
  --up-down-P4: rgba(255, 255, 255, 0);
  --up-down-P5: rgba(0, 158, 74, 0.08);
  --up-down-L1: #f6223c;
  --up-down-L2: rgba(246, 34, 60, 0.08);
  --up-down-L3: linear-gradient(
    0deg,
    rgba(246, 34, 60, 0) 0%,
    rgba(246, 34, 60, 0.08) 100%
  );
  --up-down-L4: rgba(255, 255, 255, 0);
  --up-down-L5: rgba(246, 34, 60, 0.08);
  --font-icon-T0: #ffffff;
  --font-icon-T1: #000000;
  --font-icon-T2: rgba(60, 63, 67, 0.6);
  --font-icon-T3: rgba(60, 63, 67, 0.3);
  --font-icon-T4: rgba(60, 63, 67, 0.18);
  --font-icon-T5: #006cf6;
  --font-icon-T6: rgba(120, 120, 128, 0.16);
  --font-icon-T7: #000000;
  --brand-S1: #0147bb;
  --chart-data-C1: #00c7be;
  --chart-data-C2: #30b0c7;
  --chart-data-C3: #32ade6;
  --chart-data-C4: #5856d6;
  --chart-data-C5: #f964d1;
  --chart-data-C6: #af52de;
  --chart-data-C7: #ff2d55;
  --chart-data-C8: #a2845e;
  --chart-data-C9: #8e8e93;
  --chart-data-C10: #aeaeb2;
  --chart-data-C11: linear-gradient(
    180deg,
    rgba(133, 133, 135, 0.08) 0%,
    rgba(133, 133, 135, 0.6) 50%,
    rgba(133, 133, 135, 0.08) 100%
  );
  --el-color-primary-light-9: #ecf5ff;
  --el-color-primary-light-8: #d9ecff;
  --el-color-primary-light-7: #c6e2ff;
  --el-color-primary-light-6: #b3d8ff;
  --el-color-primary-light-5: #a0cfff;
  --el-color-primary-light-4: #8cc5ff;
  --el-color-primary-light-3: #79bbff;
  --el-color-primary-light-2: #66b1ff;
  --el-color-primary-light-1: #53a8ff;

  --color-theme-main: #0047bb;
  --color-theme-green: #0b9981;
  --color-theme-red: #f23645;
  --color-l1: #f23645;
  --color-l2: #eb42561f;
  --color-p1: #0b9981;
  --background-w1: #f1f3f6;
  --background-w2: #ffffff;
  --background-w3: #f6f6f7;
  --background-w4: #ffffffff;
  --background-w6: #dfe0ea;
  --background-w8: #ffffff;
  --background-w16: #1e232c0f;
  --background-w29: #ffffff;
  --background-W2: #ffffff;
  --background-W3: #f6f6f7;
  --background-W6: #ececec;
  --font-icon-t2: #3c3f4399;
  --color-h2: 0 3px 13px 0 rgba(0, 0, 0, 0.17);
  --el-text-color-primary: #000000;
  --el-text-color-regular: #000000;
  --el-text-color-secondary: #3c3f4399;
  --el-color-primary: #006cf6;
  --color-b1: #006cf6;
  --color-b2: #0067ea;
  --color-b4: #006cf699;
  --color-b5: #006cf63d;
  --cc-font-size-11: 11px;
  --cc-color-white: #ffffff;
  --cc-text-font-weight: 600;
  --el-menu-active-color: #006cf6;
  --el-font-size-extra-small: 12px;
  --el-font-size-base: 12px;
  --el-font-size-medium: 14px;
  --el-font-size-large: 16px;
  --cc-font-size-32: 32px;
  --cc-font-size-9: 9px;
  --el-footer-height: 38px;
  --color-y1: #ffae00;
  --font-icon-T1: #000000;
  --font-icon-t3: #3c3f434d;
  --font-icon-T4: rgba(60, 63, 67, 0.18);
  --primary-B1: #006cf6;
  --primary-B4: rgba(0, 108, 246, 0.6);

  // 2024-06-20
  --v-primary-color: #0057d7;
  --v-layout-bg-color: #fff;
  --v-container-bg-color: #fff;
  --v-grey-bg-color: #f6f5f8;
  --v-text-color: #000;
  --v-text-color-grey: rgba(0, 0, 0, 0.85);
  --v-text-color-secondary: rgba(0, 0, 0, 0.45);
  --v-border-color: rgba(0, 0, 0, 0.15);
  --v-border-color-split: rgba(0, 0, 0, 0.09);

  &.dark {
    --v-layout-bg-color: #141414;
    --v-container-bg-color: #252525;
    --v-grey-bg-color: #363538;
    --v-text-color: #fff;
    --v-text-color-grey: rgba(255, 255, 255, 0.85);
    --v-text-color-secondary: rgba(255, 255, 255, 0.45);
    --v-border-color: rgba(255, 255, 255, 0.15);
    --v-border-color-split: rgba(255, 255, 255, 0.09);
  }
}

.container-h {
  min-height: calc(100vh - 50px - 62px);
}

.container-h2 {
  min-height: calc(100vh - 50px - 360px);
}

.pc {
  display: block !important;
}

.h5 {
  display: none !important;
}

.app {
  padding-top: 106px;
  // overflow: hidden;
}
.el-tooltip__popper {
  max-width: 400px;
  box-shadow: 0 0 4px 6px rgba(0, 0, 0, 0.07);
}
// 按钮样式

.el-button {
  --el-button-disabled-text-color: var(--el-text-color-regular) !important;
  --el-button-disabled-bg-color: var(--el-color-primary) !important;
}

// .el-button--primary {
//   --el-button-text-color: var(--el-color-white);
//   --el-button-bg-color: var(--el-color-primary);
//   --el-button-border-color: var(--el-color-primary);
//   --el-button-hover-text-color: var(--el-color-white);
//   --el-button-hover-bg-color: var(--el-color-primary-light-3);
//   --el-button-hover-border-color: var(--el-color-primary-light-3);
//   --el-button-active-bg-color: var(--el-color-primary-dark-2);
//   --el-button-active-border-color: var(--el-color-primary-dark-2);
//   --el-button-disabled-text-color: var(--el-color-white);
//   --el-button-disabled-bg-color: var(--el-color-primary-light-5);
//   --el-button-disabled-border-color: var(--el-color-primary-light-5);
// }

// .el-button--primary {
//   --el-button-hover-bg-color: #3187F7 !important;
//   --el-button-hover-border-color: #3187F7 !important;
//   --el-button-active-bg-color: var(--color-b2) !important;
//   --el-button-active-border-color: var(--color-b2) !important;
//   --el-button-text-color: var(--cc-color-white) !important;
//   --el-button-hover-text-color: var(--cc-color-white) !important;
// }

// 分页器样式
.el-pagination {
  margin-top: 40px;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #337ab7 !important;
  border-color: #337ab7;
}

.el-button--primary {
  --el-button-hover-bg-color: #3187f7 !important;
  --el-button-hover-border-color: #3187f7 !important;
  --el-button-active-bg-color: var(--color-b2) !important;
  --el-button-active-border-color: var(--color-b2) !important;
  --el-button-text-color: var(--cc-color-white) !important;
  --el-button-hover-text-color: var(--cc-color-white) !important;
}

// .el-dropdown-menu {
//   width: 200px;

//   .el-dropdown-menu__item {
//     font-size: 14px;
//     font-weight: 400;
//     color: #000000;

//     &:focus,
//     &:not(.is-disabled):hover {
//       background-color: #f5f5f5 !important;
//       color: #000000;
//     }
//   }
// }

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--font-icon-t3);
  background: transparent;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: var(--font-icon-t3);
}

::-webkit-scrollbar-track {
  background: var(--el-color-white);
}

.el-scrollbar {
  --el-scrollbar-opacity: 1;
  --el-scrollbar-bg-color: var(--font-icon-t3);
  --el-scrollbar-hover-opacity: 1;
  --el-scrollbar-hover-bg-color: var(--font-icon-t3);
}

.el-scrollbar__bar.is-vertical {
  width: 4px !important;
}

.el-scrollbar__bar.is-horizontal {
  height: 4px !important;
}

@media screen and (max-width: 767px) {
  .app-container {
    width: 95% !important;
  }

  .pc {
    display: none !important;
  }

  .h5 {
    display: block !important;
  }
}

.btn {
  text-align: center;

  .el-button {
    width: initial;
    margin: 40px auto 0 auto !important;
    height: 50px;
    line-height: 50px;
    padding: 0 104px;
    background: #305bbb;
    color: #ffffff;
  }
}

body {
  --theme-green: #00ae39;
  --theme-red: #ea5f5f;
  --theme-main: #121212;
  --theme-grey: #999999;
  --theme-blue: #0047bb;

  .el-message-box {
    background: #161a1e;
    box-shadow: 0px 2px 105px 26px rgba(44, 49, 56, 0.1);
    border: none;

    .el-message-box__title {
      color: #fff;
    }

    .el-message-box__message {
      color: #fff;
    }

    .btns {
      .el-button {
        background-color: var(--theme-main);
        color: #fff;
        border: 1px solid var(--theme-main);

        &:nth-of-type(2) {
          background-color: #fff;
          color: var(--theme-main);
          border: 1px solid var(--theme-main);
        }
      }
    }
  }
}

.el-button {
  &.bg-theme-green {
    &:focus,
    &:hover {
      background-color: var(--theme-green);
    }
  }
}

.el-table__empty-block {
  .el-table__empty-text {
    line-height: 1.2;
    padding: 50px 0;
  }

  .iconfont {
    font-size: 120px;
    color: var(--theme-grey);
  }

  span {
    color: var(--theme-grey);
  }
}

.el-dialog {
}
</style>
